<template>
  <div class="bg" style="width: 100vw; height: 100vh;">
    <div style="width: 100vw; height: 6vh;">
      <v-row class="pa-0 ma-0" align="center" justify="center" style="height: 6vh;">
        <v-col class="pa-0 ma-0">
          <v-img src="@/assets/hogreen-logo.png" :width="130" v-on:click="OnClickGoHome" style="cursor: pointer;"></v-img>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: center;">
          <h1><strong>REPORT</strong></h1>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: right;">
          <v-btn small @click="OnClickGoHome"><v-icon small>mdi-home</v-icon>home</v-btn>
          &nbsp;
          <v-btn small @click="OnClickGoReport"><v-icon small>mdi-chart-line</v-icon>report</v-btn>
          &nbsp;
          <v-chip small label>
            <v-icon small>mdi-account</v-icon>{{ store_auth_id }}<v-btn plain small @click="OnClickLogout"><v-icon small>mdi-logout</v-icon>logout</v-btn>
          </v-chip>
        </v-col>
      </v-row>
    </div>

    <v-row class="pa-0 ma-0" style="width: 100vw; height: 94vh;">
      <v-col cols="2" class="pa-0 ma-0" style="height: 94vh;">
        <v-container class="pa-0 ma-0" style="height: 25vh;">

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-text-field v-model="search_text" hide-details="" dense outlined placeholder="search"></v-text-field>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="primary secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black primary--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">CONNECT</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().run }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="warning secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black warning--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">WARNING</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().warning }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="error secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black error--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">ERROR</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().error }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="grey secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black grey--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">DISCONNECT</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().offline }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

        </v-container>

        <v-container class="pa-0 ma-0" style="height: 69vh; overflow-y: auto;">
          <v-row class="pa-1 ma-0" v-for="device in devices.filter(v => v.Get().device_serial.includes(search_text))"
            :key="device.Get().device_serial">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="pa-1 ma-0" elevation="10" :color="hover ? 'grey' : 'default'"
                  v-on:click="OnClickDevice(device.Get().device_serial)" width="100%">
                  <v-card-subtitle class="pa-0 ma-0 d-flex">
                    <strong>{{ device.Get().device_index_alias }} ({{ device.Get().stack_list.length }})</strong>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="secondary" disabled>device</v-btn>
                  </v-card-subtitle>
                  <v-card-text class="pa-0 ma-0" v-for="stack_serial in device.Get().stack_list.sort()"
                    :key="stack_serial">

                    <v-container class="pa-0 ma-0 d-flex">
                      <v-row class="pa-0 ma-0" align="center" justify="end">
                        <v-col class="pa-0 ma-0 d-flex align-center justify-end">
                          {{ stack_serial }}
                          <v-icon v-if="(stack_manager.GetStackFromStackSerial(stack_serial)?.Get().summary_run_state)"
                            color="yellow" small>
                            mdi-lightning-bolt
                          </v-icon>
                          <v-icon v-else color="grey" small>
                            mdi-lightning-bolt
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>

                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-row>

          <v-row class="pa-1 ma-0" v-for="stack in nav_stack_infos.filter(v => v.stack_serial.includes(search_text))"
            :key="stack.stack_serial">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="pa-1 ma-0" elevation="10"
                  :color="selected_stack?.Get().stack_serial == stack.stack_serial ? 'primary' : hover ? 'grey' : 'default'"
                  v-on:click="OnClickStack(stack.stack_serial)" width="100%">
                  <v-card-subtitle class="pa-0 ma-0 d-flex">
                    <v-icon x-small v-if="stack.connect_state == 1 && stack.error_state == 0"
                      color="success">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 1"
                      color="warning">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 2"
                      color="warning">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 3"
                      color="error">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 4"
                      color="error">mdi-square</v-icon>
                    <v-icon x-small v-else color="grey">mdi-square</v-icon>
                    &nbsp;
                    <strong>{{ stack.stack_serial }}</strong>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="secondary" disabled>
                      {{ stack.model_type == 0 ? 'IE-POWER 4' : stack.model_type == 1 ? 'IE-SOAR 2.4' : 'UNKNOWN' }}
                    </v-btn>

                  </v-card-subtitle>
                </v-card>
              </template>
            </v-hover>
          </v-row>
        </v-container>

      </v-col>

      <v-col cols="10" class="pa-1 ma-0" style="height: 94vh;">
        <v-row class="pa-0 ma-0" align="center">

          <v-spacer></v-spacer>

          <v-col cols="2" class="pa-0 ma-2">
            <v-text-field class="secondary" type="date" v-model="search_start_date" outlined dense
              hide-details></v-text-field>
          </v-col>
          ~
          <v-col cols="2" class="pa-0 ma-2">
            <v-text-field class="secondary" type="date" dark v-model="search_end_date" outlined dense
              hide-details></v-text-field>
          </v-col>

          <v-col cols="2" class="pa-0 ma-1">
            <v-text-field prepend-inner-icon="mdi-magnify" v-model="search_serial" outlined dense hide-details
              placeholder="search" clearable></v-text-field>
          </v-col>

        </v-row>

        <v-row class="pa-0 ma-0">

          <v-col class="pa-0 ma-0" cols="12">
            <v-data-table dense height="22vh" :headers="tb_headers" :items="log_files" :items-per-page="5"
              :search="`${search_serial}${search_start_date}${search_end_date}`" :custom-filter="CustomFilter"
              :sort-by="['create_datetime']" :sort-desc="true" class="transparent">

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn width="50%" v-if="IncludeAnalyzeItem(item.file_name)" x-small color="grey"
                  @click="AnalyzeRemoveItem(item.file_name)">del</v-btn>
                <v-btn width="50%" v-else x-small color="primary" @click="AnalyzeAddItem(item)">add</v-btn>
              </template>

              <template v-slot:[`item.model_type`]="{ item }">
                {{ item.model_type == 0 ? 'IE-POWER 4' : 'IE-SOAR 2.4' }}
              </template>

              <template v-slot:[`item.connection_type`]="{ item }">
                {{ item.connection_type == 0 ? 'UART' : 'NETWORK' }}
              </template>

              <template v-slot:[`item.connect_datetime`]="{ item }">
                {{ DateFormat(item.connect_datetime) }}
              </template>

              <template v-slot:[`item.create_datetime`]="{ item }">
                {{ DateFormat(item.create_datetime) }}
              </template>

            </v-data-table>
          </v-col>
        </v-row>


        <v-row class="pa-0 ma-0" style="height: 58vh; overflow-y: auto;">
          <v-card class="transparent" style="width: 100%;">
            <v-card-title v-if="selected_files.length != 0">
              <strong>{{ selected_files[0].stack_serial }}</strong> Report
              <v-spacer></v-spacer>
              <v-btn samll color="primary" @click="RawDownload"><v-icon>mdi-download</v-icon>raw</v-btn>
            </v-card-title>
            <v-chip class="ma-1" close v-for="file in selected_files" :key="file.file_name"
              @click:close="AnalyzeRemoveItem(file.file_name)">
              <v-chip small>Recording from {{ DateFormat(file.create_datetime) }}</v-chip>
              <v-chip small>Index {{ file.file_index }}</v-chip>
            </v-chip>
            <v-card-subtitle v-if="selected_files.length == 0" class="d-flex align-center justify-center"
              style="height: 58vh;">
              There is no selected files.
            </v-card-subtitle>
            <v-tabs v-model="analyze_tab" v-show="selected_files.length != 0">
              <template v-if="SelectedModelType() == 0">
                <v-tab :value="0">STATE</v-tab>
                <v-tab :value="1">OUTPUT</v-tab>
                <v-tab :value="2">ANODE PRESSURE</v-tab>
                <v-tab :value="3">FAN</v-tab>
                <v-tab :value="4">LOUVER</v-tab>
                <v-tab :value="5">TEMPERATURE</v-tab>
              </template>
              <template v-else-if="SelectedModelType() == 1">
                <v-tab :value="0">STATE</v-tab>
                <v-tab :value="1">DATA</v-tab>
              </template>
            </v-tabs>

            <!-- SOAR -->
            <v-window :value="analyze_tab" v-if="selected_files.length != 0 && SelectedModelType() == 1">

              <v-window-item :value="0">
                <apexchart ref="chart_soar_state" :height="400" :options="range_chart_options"
                  :series="soar_state_series"></apexchart>
              </v-window-item>

              <v-window-item :value="1">
                <apexchart ref="chart_soar_data" :height="400" :options="line_chart_options" :series="soar_data_series">
                </apexchart>
              </v-window-item>

            </v-window>

            <!-- LIFT -->
            <v-window :value="analyze_tab" v-show="selected_files.length != 0 && SelectedModelType() == 0">



              <v-window-item :value="0">
                <apexchart ref="chart_state" :height="400" :options="range_chart_options" :series="state_series">
                </apexchart>
              </v-window-item>

              <v-window-item :value="1">
                <apexchart ref="chart_output" :height="400" :options="line_chart_options" :series="output_series">
                </apexchart>
              </v-window-item>

              <v-window-item :value="2">
                <apexchart ref="chart_anode_pressure" :height="400" :options="line_chart_options"
                  :series="anode_pressure_series"></apexchart>
              </v-window-item>

              <v-window-item :value="3">
                <apexchart ref="chart_fan_pulse" :height="200" :options="range_chart_options" :series="fan_pulse_series">
                </apexchart>
                <apexchart ref="chart_fan_sp_duty" :height="200" :options="line_chart_options"
                  :series="fan_sp_duty_series"></apexchart>
              </v-window-item>

              <v-window-item :value="4">
                <apexchart ref="chart_louver_open" :height="200" :options="range_chart_options"
                  :series="louver_open_series"></apexchart>
                <apexchart ref="chart_louver_position" :height="200" :options="line_chart_options"
                  :series="louver_position_series"></apexchart>
              </v-window-item>

              <v-window-item :value="5">
                <apexchart ref="chart_temperature" :height="400" :options="line_chart_options"
                  :series="temperature_series"></apexchart>
              </v-window-item>

            </v-window>
          </v-card>


        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import DEVICE from '@/services/device/Device';
import { LINE_CHART_SERIES_FROM, RANGE_CHART_SERIES_FORM, ST_LOG_FILE_INFO } from '@/services/report/LogFileManager';
import STACK from '@/services/stack/Stack';
import StackManager, { NAV_STACK_INFO } from '@/services/stack/StackManager';
import { STRUCT_HYDROGEN_LOG_INFO2 } from '@/services/websocket/WebsocketInterface';
import { RequestStackLogFileData, RequestStackLogFileList, StopReceiveStackData } from '@/services/websocket/WebsocketSender';
import store from '@/store';
import Vue, { computed } from 'vue'
import VueApexCharts from 'vue-apexcharts'
import LogFileManager from '@/services/report/LogFileManager';

export default Vue.extend({
  name: 'ReportComponent',

  props: {
    stack_serial: {
      type: String,
      default: '',
    },
  },

  components: {
    apexchart: VueApexCharts
  },

  data() {
    const dt_today = new Date();
    const dt_before_week = new Date();
    dt_before_week.setFullYear(dt_today.getFullYear(), dt_today.getMonth(), dt_today.getDate() - 7);
    return {
      analyze_tab: null,
      soar_state_series: [
        {
          data: [],
        }
      ],
      state_series: [
        {
          name: 'STATE',
          data: [],
        }
      ],
      fan_pulse_series: [
        {
          data: [],
        }
      ],
      fan_sp_duty_series: [
        {
          data: [],
        }
      ],
      louver_open_series: [
        {
          data: [],
        }
      ],
      louver_position_series: [
        {
          data: [],
        }
      ],
      temperature_series: [
        {
          data: [],
        }
      ],
      anode_pressure_series: [
        {
          data: [],
        }
      ],
      output_series: [
        {
          data: [],
        }
      ],
      soar_data_series: [
        {
          data: [],
        }
      ],
      line_chart_options: {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
            // tools: {
            //   download: false,
            //   selection: false,
            //   zoom: false,
            //   zoomin: false,
            //   zoomout: false,
            //   pan: false,
            //   reset: true,
            //   customIcons: []
            // },
          },
          foreColor: '#fff',
          animations: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            formatter: function (value: number) {
              const dt = new Date(value);
              const yyyy = dt.getFullYear().toString().padStart(4, '0');
              const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
              const dd = dt.getDate().toString().padStart(2, '0');
              const hh = dt.getHours().toString().padStart(2, '0');
              const mm = dt.getMinutes().toString().padStart(2, '0');
              const ss = dt.getSeconds().toString().padStart(2, '0');
              return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            }
          },
        },
        yaxis: {
          min: 0,
          // title: {
          //   text: 'Output'
          // },
        },
        legend: {
          show: true
        },
      },
      range_chart_options: {
        chart: {
          // toolbar: {
          //   show: false,
          //   tools: {
          //     download: false,
          //     selection: false,
          //     zoom: false,
          //     zoomin: false,
          //     zoomout: false,
          //     pan: false,
          //     reset: true,
          //     customIcons: []
          //   },
          // },
          foreColor: '#ffffff',
          type: 'rangeBar'
        },
        // tooltip: {
        //   enabled: false,
        //   onDatasetHover: {
        //     highlightDataSeries: false,
        //   },
        // },
        tooltip: {
          enabled: true,
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
          custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let period = 0;
            if (data.y.length == 2) {
              period = (data.y[1] - data.y[0]);
            }
            return '<span style="margin: 10px;">' + '<b>' + data.x + '</b>: ' + period + 'ms' + '</span>';
          },
          x: {
            show: true,
            format: 'yyyy-MM-dd hh:mm:ss',

            // formatter: function(value:number) {
            //   return new Date(value).toLocaleString()
            // }
            // formatter: function (value:number) {
            //   const dt = new Date(value);
            //   const yyyy = dt.getFullYear().toString().padStart(4, '0');
            //   const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
            //   const dd = dt.getDate().toString().padStart(2, '0');
            //   const hh = dt.getHours().toString().padStart(2, '0');
            //   const mm = dt.getMinutes().toString().padStart(2, '0');
            //   const ss = dt.getSeconds().toString().padStart(2, '0');
            //   return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            // }
          },
          // y: {
          //   // format: 'yyyy-MM-dd hh:mm:ss',
          //   formatter: undefined,
          //   title: {
          //     formatter: function(value:string, { series, seriesIndex, dataPointIndex, w }:any) {
          //       console.log(value, series, seriesIndex, dataPointIndex, w)
          //       return ''
          //     }
          //     // formatter: function(value:string) {
          //     //   console.log(value)
          //     //   return value
          //     // }
          //   },
          // },
          // z: {
          //   formatter: undefined,
          //   title: 'Size: '
          // },
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            formatter: function (value: number) {
              const dt = new Date(value);
              const yyyy = dt.getFullYear().toString().padStart(4, '0');
              const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
              const dd = dt.getDate().toString().padStart(2, '0');
              const hh = dt.getHours().toString().padStart(2, '0');
              const mm = dt.getMinutes().toString().padStart(2, '0');
              const ss = dt.getSeconds().toString().padStart(2, '0');
              return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            }
          },
        },
        // yaxis: {
        //   title: {
        //     text: 'State'
        //   },
        // },
      },
      selected_files: [] as ST_LOG_FILE_INFO[],
      search_text: '' as string,
      search_serial: '' as string,
      search_start_date: `${dt_before_week.getFullYear()}-${(dt_before_week.getMonth() + 1).toString().padStart(2, '0')}-${dt_before_week.getDate().toString().padStart(2, '0')}`,
      search_end_date: `${dt_today.getFullYear()}-${(dt_today.getMonth() + 1).toString().padStart(2, '0')}-${dt_today.getDate().toString().padStart(2, '0')}`,
      stack_manager: StackManager.getInstance() as StackManager,
      selected_stack: StackManager.getInstance().GetStackFromStackSerial(this.stack_serial),
      stacks: [] as STACK[],
      devices: [] as DEVICE[],
      nav_stack_infos: [] as NAV_STACK_INFO[],
      log_files: [] as ST_LOG_FILE_INFO[],
      tb_headers: [
        // {
        //   text: 'No',
        //   value: 'idx',
        // },
        {
          text: 'Stack serial',
          value: 'stack_serial'
        },
        {
          text: 'Device serial',
          value: 'device_serial'
        },
        {
          text: 'Model type',
          value: 'model_type'
        },
        {
          text: 'Connection type',
          value: 'connection_type'
        },
        {
          text: 'Access time',
          value: 'connect_datetime'
        },
        {
          text: 'Recording time',
          value: 'create_datetime'
        },
        {
          text: 'Index',
          value: 'file_index'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    RequestStackLogFileList();
  },

  setup() {
    const store_auth_id = computed<string>(() => store.getters.GetAuthId);
    const store_stacks = computed<STACK[]>(() => store.getters.GetStacks);
    const store_devices = computed<DEVICE[]>(() => store.getters.GetDevices);
    const store_nav_stack_infos = computed<NAV_STACK_INFO[]>(() => store.getters.GetStackNavList);
    const store_log_files = computed<ST_LOG_FILE_INFO[]>(() => store.getters.GetLogFiles);

    const store_state_data = computed<RANGE_CHART_SERIES_FORM[]>(() => store.getters.GetReportState);

    const store_fan_pulse_data = computed<RANGE_CHART_SERIES_FORM[]>(() => store.getters.GetReportFanPulse);
    const store_fan_sp_duty_data = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportFanSpDuty);

    const store_output_data = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportOutput);
    const store_anode_pressure_data = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportAnodePressure);

    const store_louver_open_data = computed<RANGE_CHART_SERIES_FORM[]>(() => store.getters.GetReportLouverOpen);
    const store_louver_position_data = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportLouverPosition);

    const store_temperature_data = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportTemperature);

    const store_soar_state_data = computed<RANGE_CHART_SERIES_FORM[]>(() => store.getters.GetReportSoarState);
    const store_soar_data = computed<LINE_CHART_SERIES_FROM[]>(() => store.getters.GetReportSoarData);
    return {
      store_auth_id, store_stacks, store_devices, store_nav_stack_infos
      , store_log_files, store_state_data, store_fan_pulse_data
      , store_output_data, store_anode_pressure_data, store_louver_open_data
      , store_fan_sp_duty_data, store_louver_position_data, store_temperature_data
      , store_soar_state_data, store_soar_data
    };
  },

  watch: {
    store_stacks: {
      handler(val, oldVal) {
        this.stacks = val;
      },
    },
    store_devices: {
      handler(val, oldVal) {
        this.devices = val;
      },
    },
    store_nav_stack_infos: {
      handler(val, oldVal) {
        this.nav_stack_infos = val;
      },
    },
    store_log_files: {
      handler(val, oldVal) {
        console.log(val)
        this.log_files = val;
      },
    },
    store_state_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_state;
        if (chart) chart.updateSeries([{ data: val }], true)
        this.state_series = [{ name: 'STATE', data: val }];
          console.log(this.state_series)
      },
    },
    store_soar_state_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_soar_state;
        if (chart) chart.updateSeries([{ data: val }], true)
        this.soar_state_series = [{ data: val }];
      },
    },
    store_fan_pulse_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_fan_pulse;
        if (chart) chart.updateSeries([{ data: val }], true)
        this.fan_pulse_series = [{ data: val }];
      },
    },
    store_output_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_output;
        if (chart) chart.updateSeries(val, true)
        this.output_series = val;
      },
    },
    store_anode_pressure_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_anode_pressure;
        if (chart) chart.updateSeries(val, true)
        this.anode_pressure_series = val;
      },
    },
    store_fan_sp_duty_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_fan_sp_duty;
        if (chart) chart.updateSeries(val, true)
        this.fan_sp_duty_series = val;
      },
    },
    store_louver_open_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_louver_open;
        if (chart) chart.updateSeries([{ data: val }], true)
        this.louver_open_series = [{ data: val }];
      },
    },
    store_louver_position_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_louver_position;
        if (chart) chart.updateSeries(val, true)
        this.louver_position_series = val;
      },
    },
    store_temperature_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_temperature;
        if (chart) chart.updateSeries(val, true)
        this.temperature_series = val;
      },
    },
    store_soar_data: {
      handler(val, oldVal) {
        const chart: any = this.$refs.chart_soar_data;
        if (chart) chart.updateSeries(val, true)
        this.soar_data_series = val;
      },
    },

  },

  methods: {
    SelectedModelType() {
      let type = 0;
      this.selected_files.forEach((selected_file: ST_LOG_FILE_INFO) => {
        type = selected_file.model_type;
      });
      return type;
    },
    Analyze() {
      const send_file_name_list: STRUCT_HYDROGEN_LOG_INFO2[] = [];
      this.selected_files.forEach((selected_file: ST_LOG_FILE_INFO) => {
        send_file_name_list.push({
          _filename: selected_file.file_name,
        })
      });
      RequestStackLogFileData(send_file_name_list);
    },
    IncludeAnalyzeItem(file_name: string) {
      let include = false;
      this.selected_files.forEach((selected_file: ST_LOG_FILE_INFO, index: number) => {
        if (file_name == selected_file.file_name) {
          include = true;
        }
      });

      return include;
    },
    AnalyzeRemoveItem(file_name: string) {
      this.selected_files.forEach((selected_file: ST_LOG_FILE_INFO, index: number) => {
        if (file_name == selected_file.file_name) {
          this.selected_files.splice(index, 1);
        }
      });

      this.Analyze();
    },
    AnalyzeAddItem(file_info: ST_LOG_FILE_INFO) {

      this.search_serial = file_info.stack_serial;

      let exist = false;
      this.selected_files.forEach((selected_file: ST_LOG_FILE_INFO) => {
        if (file_info.file_name == selected_file.file_name) {
          exist = true;
        }
      });
      if (exist) {
        return;
      }

      let is_another_stack = false;
      this.selected_files.forEach((selected_file: ST_LOG_FILE_INFO) => {
        if (file_info.stack_serial != selected_file.stack_serial) {
          is_another_stack = true;
        }
      });
      if (is_another_stack) {
        this.selected_files = [];
      }

      this.selected_files.push(file_info);

      this.selected_files.sort(function (a: ST_LOG_FILE_INFO, b: ST_LOG_FILE_INFO) {
        if (a.create_datetime > b.create_datetime) return 1;
        if (a.create_datetime < b.create_datetime) return -1;
        return 0;
      })

      this.Analyze();
    },
    CustomFilter(value: ST_LOG_FILE_INFO, query: string, item: ST_LOG_FILE_INFO) {
      if (new Date(this.search_start_date) <= item.create_datetime && item.create_datetime <= new Date(this.search_end_date + 'T23:59:59')) {
        if (this.search_serial
          ? (item.stack_serial.includes(this.search_serial.trim()) || item.device_serial.includes(this.search_serial.trim()))
          : true) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    DateFormat(dt: Date) {
      const yyyy = dt.getFullYear().toString().padStart(4, '0');
      const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
      const dd = dt.getDate().toString().padStart(2, '0');
      const hh = dt.getHours().toString().padStart(2, '0');
      const mm = dt.getMinutes().toString().padStart(2, '0');
      const ss = dt.getSeconds().toString().padStart(2, '0');
      return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
    },
    OnClickGoHome() {
      StopReceiveStackData();
      router.push({ name: 'home' });
    },
    OnClickGoReport() {
      // StopReceiveStackData();
      // router.push({name: 'report'});
    },
    OnClickStack(serial: string) {
      this.search_serial = serial;
    },
    OnClickDevice(serial: string) {
      this.search_serial = serial;
    },
    OnClickLogout() {
      window.location.reload();
    },
    RawDownload() {
      const data = LogFileManager.getInstance().GetLogFileRawData();

      if (data && data.length != 0) {
        let rawFile;
        let downloadLink;

        let file_name = 'Hydrogen_Stack_Raw_Data'

        rawFile = new Blob([data], { type: "text/plain" });
        downloadLink = document.createElement("a");
        downloadLink.download = file_name
        downloadLink.href = window.URL.createObjectURL(rawFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
      }


    }
  }
})
</script>

<style scoped>
.bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)), url(@/assets/h2img.png);
  background-position: right bottom;
}

.hidden-sm-and-down .v-icon {
  color: white !important;
}

#virtual-scroll-table {
  height: 22vh;
  overflow: auto;
}</style>

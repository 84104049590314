<template>
  <v-app>
    <!-- <v-main> -->
      <Snackbar/>
      <router-view/>
    <!-- </v-main> -->
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import WebsocketConnector from './services/websocket/WebsocketConnector';
import Snackbar from './components/Snackbar.vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
  }),

  components: {
    Snackbar
  },

  mounted() {
    WebsocketConnector.getInstance();
    // const test = 'AAADOA8mE30eXRWc';
    // const test = 'AAADIAAAAAAAAAAA\x0E';
    // const strings:string[] = test.split('');
    // strings.forEach((str:string) => {
    //   console.log(str)
    //   // String.
    // });

    // const test = 'AAADEAAAAAAAAAAAX���';
    // const test = 'AAADEAAAAAAAAAAA';
    // this.base64ToArrayBuffer(test)
    // const base64decode = atob(test);
    // console.log(base64decode, base64decode.length, btoa(base64decode))
    // for(let i = 0; i < base64decode.length; ++i) {
    //   const char = base64decode.charCodeAt(i).toString(2).padStart(8, '0');
    //   console.log(i, base64decode.charCodeAt(i).toString(16).padStart(2, '0'))
    //   // console.log(parseInt(char, 16))
    // }

    // const dd = "⩶";
    // console.log(dd.charCodeAt(0))
    // console.log(String.fromCharCode(dd.charCodeAt(0)))
    // console.log(atob('AAADIAAAAAAAAAAAXb��'))
    // console.log(btoa(String.fromCharCode(dd.charCodeAt(0))))
    // console.log(atob(btoa(String.fromCharCode(dd.charCodeAt(0)))))

    // const data = 'AAADWP86AAAAAAAAX⩶'
    // console.log(escape(atob(data)))
    // const logging_format1 = `${Date.now()} : ${data}\x0E\r\n`;
    // const logging_format2 = `${Date.now()} : ${data}`;
    // console.log(logging_format1);
    // console.log(logging_format2);
    // console.log(logging_format1);
    // console.log(logging_format2);
    // console.log(logging_format1);
    // console.log(logging_format2);

    // const arr = [0x00, 0x00, 0x03, 0x78, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x0E];
    // let str = '';
    // for(let i = 0; i < arr.length; ++i) {
    //     str += String.fromCharCode(arr[i]);
    // }
    // console.log(str)
  },

  methods: {
    base64ToArrayBuffer(base64:string) {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
      return bytes.buffer;
    }
  }
});
</script>

<style>
/* * {
 padding: 0;
 margin: 0;
 box-sizing: border-box;
} */
@font-face {
  font-family: 'NotoMedium';
  src: url('@/assets/NotoMedium_otf.otf');
}
@font-face {
  font-family: 'NotoRegular';
  src: url('@/assets/NotoRegular_otf.otf');
}
@font-face {
  font-family: 'NotoBlack';
  src: url('@/assets/NotoBlack_otf.otf');
}
* {
  font-family: 'NotoRegular';
}

/* html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>

<template>
  <div class="bg" style="width: 100vw; height: 100vh;">
    <div style="width: 100vw; height: 6vh;">
      <v-row class="pa-0 ma-0" align="center" justify="center" style="height: 6vh;">
        <v-col class="pa-0 ma-0">
          <v-img src="@/assets/hogreen-logo.png" :width="130" v-on:click="OnClickGoHome" style="cursor: pointer;"></v-img>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: center;">
          <h1><strong>Liquid Generator {{ liquid_serial }}</strong></h1>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: right;">
          <v-btn small @click="OnClickGoHome"><v-icon small>mdi-home</v-icon>home</v-btn>
          &nbsp;
          <v-btn small @click="OnClickGoReport"><v-icon small>mdi-chart-line</v-icon>report</v-btn>
          &nbsp;
          <v-chip small label>
            <v-icon small>mdi-account</v-icon>{{ store_auth_id }}<v-btn plain small @click="OnClickLogout"><v-icon small>mdi-logout</v-icon>logout</v-btn>
          </v-chip>
        </v-col>
      </v-row>
    </div>

    <v-row class="pa-0 ma-0" style="width: 100vw; height: 94vh; overflow-y: scroll;">
      <img src="../assets/lq4k.jpg" height="100%" width="100%">

      <div v-if="selected_liquid" class="overlay-wrapper" style="top: 0%; left: 0%; width: 100%; height: 100%;">

        <!-- Warning Alert -->
        <div class="overlay" style="top: 12.5%; left: 80%; width: 20%; height: 20%; justify-content: start; display: inline;">
          <div v-if="
          selected_liquid.Get().liquid_state._mfc_gs == 0 && selected_liquid.Get().liquid_state._positioner_valve < 0.01 &&
          (selected_liquid.Get().liquid_state._temperature_1 <= 0 || selected_liquid.Get().liquid_state._temperature_1 >= 20)
            ">
            <v-icon class="ma-1" x-large color="error">mdi-alert-outline</v-icon>
            TS1: {{ selected_liquid.Get().liquid_state._temperature_1 }}
          </div>

          <div v-if="
          selected_liquid.Get().liquid_state._positioner_valve > 0.01 &&
          (selected_liquid.Get().liquid_state._temperature_1 <= 0 || selected_liquid.Get().liquid_state._temperature_1 >= 30)
            ">
            <v-icon class="ma-1" x-large color="error">mdi-alert-outline</v-icon>
            TS1: {{ selected_liquid.Get().liquid_state._temperature_1 }}
          </div>

          <div v-if="
          selected_liquid.Get().liquid_state._positioner_valve > 0.01 && 
          (selected_liquid.Get().liquid_state._gh2_vent == 0 || selected_liquid.Get().liquid_state._gh2_vent > 2)
            ">
            <v-icon class="ma-1" x-large color="error">mdi-alert-outline</v-icon>
            PT4: {{ selected_liquid.Get().liquid_state._gh2_vent }}
          </div>

          <div v-if="
          selected_liquid.Get().liquid_state._positioner_valve < 0.01 && 
          (selected_liquid.Get().liquid_state._gh2_vent == 0 || selected_liquid.Get().liquid_state._gh2_vent > 1)
            ">
            <v-icon class="ma-1" x-large color="error">mdi-alert-outline</v-icon>
            PT4: {{ selected_liquid.Get().liquid_state._gh2_vent }}
          </div>

          <div v-if="selected_liquid.Get().liquid_state._coolant_in_temp >= 25"><v-icon class="ma-1" x-large color="error">mdi-alert-outline</v-icon>TC1: {{ selected_liquid.Get().liquid_state._coolant_in_temp }}</div>
        </div>

        <div class="overlay" style="top: 70.0%; left: 45%; width: 10%; height: 2.6%; font-size: large; font-weight: bold;">{{ selected_liquid.Get().liquid_state._cooler_status ? 'ON' : 'OFF' }}</div>
        <v-tooltip bottom nudge-bottom="-40">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 70.0%; left: 45%; width: 10%; height: 2.6%;"></div>
          </template>
          <span>냉동기 동작 상태</span>
        </v-tooltip>

        <div class="overlay" style="top: 20.0%; left: 3.2%; width: 9.8%; height: 2.6%;">{{ selected_liquid.Get().liquid_state._lhr.toFixed(2) }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 17.0%; left: 3%; width: 14%; height: 6%;"></div>
          </template>
          <span>시간당 액화율</span>
        </v-tooltip>
        <div class="overlay" style="top: 27.0%; left: 3.2%; width: 9.8%; height: 2.6%;">{{ selected_liquid.Get().liquid_state._lhrkw.toFixed(2) }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 24.0%; left: 3%; width: 14%; height: 6%;"></div>
          </template>
          <span>kW당 액화율</span>
        </v-tooltip>
        <div class="overlay" style="top: 33.9%; left: 3.2%; width: 9.8%; height: 2.6%;">{{ selected_liquid.Get().liquid_state._kg_per_day.toFixed(2) }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 31.0%; left: 3%; width: 14%; height: 6%;"></div>
          </template>
          <span>일일 액화율</span>
        </v-tooltip>
        

        <div class="overlay" style="top: 51.5%; left: 86.5%; width: 6.8%; height: 6.4%; font-size: xx-large;">{{ Math.round(selected_liquid.Get().liquid_state._positioner_valve) }}&nbsp;%</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 51.5%; left: 86.5%; width: 6.8%; height: 6.4%; font-size: xx-large;"></div>
          </template>
          <span>트랜스퍼 밸브 개폐값</span>
        </v-tooltip>

        <div class="overlay" style="top: 51.65%; left: 8.33%; width: 6.8%; height: 3.2%;">{{ selected_liquid.Get().liquid_state._gh2_inlet }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 48.2%; left: 8.33%; width: 6.8%; height: 6.5%;"></div>
          </template>
          <span>수소 공급압</span>
        </v-tooltip>

        <div class="overlay" style="top: 59.2%; left: 2.95%; width: 6.8%; height: 3.2%;">{{ selected_liquid.Get().liquid_state._mfc_gs }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 55.8%; left: 2.95%; width: 6.8%; height: 6.5%;"></div>
          </template>
          <span>MFC 유량값</span>
        </v-tooltip>

        <div class="overlay" style="top: 35.25%; left: 28.75%; width: 6.8%; height: 3.2%;">{{ selected_liquid.Get().liquid_state._coolant_in_temp.toFixed(2) }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 32%; left: 28.75%; width: 6.8%; height: 6.5%;"></div>
          </template>
          <span>냉각수 입구 온도</span>
        </v-tooltip>

        <div class="overlay" style="top: 66.85%; left: 17.65%; width: 6.8%; height: 3.2%;">{{ selected_liquid.Get().liquid_state._coolant_out_temp.toFixed(2) }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 63.4%; left: 17.65%; width: 6.8%; height: 6.5%;"></div>
          </template>
          <span>냉각수 출구 온도</span>
        </v-tooltip>

        <div class="overlay" style="top: 35.25%; left: 44.2%; width: 6.8%; height: 3.2%;">{{ selected_liquid.Get().liquid_state._vacuum }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 31.9%; left: 44.2%; width: 6.8%; height: 6.5%;"></div>
          </template>
          <span>진공측 진공도</span>
        </v-tooltip>

        <div class="overlay" style="top: 66.85%; left: 37.8%; width: 6.8%; height: 3.2%;">{{ selected_liquid.Get().liquid_state._outer_tank_vacuum }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 63.4%; left: 37.8%; width: 6.8%; height: 6.5%;"></div>
          </template>
          <span>외통 진공도</span>
        </v-tooltip>

        <div class="overlay" style="top: 54.7%; left: 69.9%; width: 6.8%; height: 3.2%;">{{ selected_liquid.Get().liquid_state._gh2_vent.toFixed(2) }}</div>
        <v-tooltip bottom nudge-bottom="-60">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 51.2%; left: 69.9%; width: 6.8%; height: 6.5%;"></div>
          </template>
          <span>수소 밴트압</span>
        </v-tooltip>

        <div class="overlay small-text" style="top: 85.4%; left: 13.3%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_3.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_3 - 273.15).toFixed(1) }}℃</div>
        <div class="overlay small-text" style="top: 85.4%; left: 25.4%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_6.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_6 - 273.15).toFixed(1) }}℃</div>

        <div class="overlay small-text" style="top: 88.9%; left: 13.3%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_4.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_4 - 273.15).toFixed(1) }}℃</div>
        <div class="overlay small-text" style="top: 88.9%; left: 25.4%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_7.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_7 - 273.15).toFixed(1) }}℃</div>

        <div class="overlay small-text" style="top: 92.4%; left: 13.3%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_5.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_5 - 273.15).toFixed(1) }}℃</div>
        <div class="overlay small-text" style="top: 92.4%; left: 25.4%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_8.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_8 - 273.15).toFixed(1) }}℃</div>

        <v-tooltip bottom nudge-bottom="-80">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 85.4%; left: 8.3%; width: 24%; height: 10%;"></div>
          </template>
          <span>내부 용기 온도</span>
        </v-tooltip>

        <div class="overlay small-text" style="top: 85.4%; left: 72.4%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_1.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_1 - 273.15).toFixed(1) }}℃</div>
        <div class="overlay small-text" style="top: 88.9%; left: 72.4%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_2.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_2 - 273.15).toFixed(1) }}℃</div>
        <div class="overlay small-text" style="top: 92.4%; left: 72.4%; width: 6%; height: 2.4%;">{{ selected_liquid.Get().liquid_state._temperature_10.toFixed(1) }}/{{ (selected_liquid.Get().liquid_state._temperature_10 - 273.15).toFixed(1) }}℃</div>
        <v-tooltip bottom nudge-bottom="-80">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on" class="hoverlay" style="top: 85.4%; left: 67.4%; width: 11%; height: 10%;"></div>
          </template>
          <span>냉동기 온도</span>
        </v-tooltip>

      </div>
    </v-row>

  </div>
</template>

<script lang="ts">
import router from '@/router';
import LIQUID from '@/services/liquid/Liquid';
import LiquidManager from '@/services/liquid/LiquidManager';
import { STRUCT_LIQUID_STATE } from '@/services/websocket/WebsocketInterface';
import store from '@/store';
import moment from 'moment';
import Vue, { computed } from 'vue'

interface LOG_INFO {
  _datetime: string,
  _serial: string,
  _liquid_state: STRUCT_LIQUID_STATE,
}

export default Vue.extend({
  name: 'StackDetail',

  props: {
    liquid_serial: {
      type: String,
      default: '',
    },
  },

  data() {
    
    return {
      motor_state: false,
      selected_liquid: LiquidManager.getInstance().GetLiquidFromSerial(this.liquid_serial) as LIQUID|undefined,
    };
  },

  mounted() {
    //  
  },

  setup() {
    const store_auth_id = computed<string>(() => store.getters.GetAuthId);
    return { store_auth_id };
  },

  watch: {
    // 
  },

  methods: {
    OnClickGoHome() {
      router.push({name: 'home'});
    },
    OnClickGoReport() {
      router.push({name: 'report'});
    },
    OnClickLogout() {
      window.location.reload();
    },
    OnClickMotor() {
      this.motor_state = !this.motor_state;
    }
  }
})
</script>

<style scoped>
.bg {
  background-image: linear-gradient(
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),url(@/assets/h2img.png);
  background-position: right bottom;
}

.small-text {
  /* font-size:small; */
}

.overlay-wrapper {
  position: absolute;
  pointer-events: none;
}
.overlay {
  position: absolute;
  /* background-color: #ff000044; */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  color: #EEEEEE;
}
.hoverlay {
  position: absolute;
  /* background-color: #0000ff44; */
  pointer-events: auto;
  /* display: flex;
  align-items: center;
  justify-content: center;
  color: #EEEEEE; */
}
</style>

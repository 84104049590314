import store from "@/store";
import { STRUCT_HYDROGEN_LOG_INFO } from "../websocket/WebsocketInterface";
import { ToInt16 } from "../util/Converter";
import { STACK_CONN_TYPE } from "../stack/Stack";

export interface ST_LOG_FILE_INFO {
    idx: number,
    file_name: string,
    device_serial: string,
    stack_serial: string,
    model_type: number,
    connection_type: number,
    connect_datetime: Date,
    create_datetime: Date,
    file_index: number,
}

interface LIFT_0x368_INFO {
    inactive: number,
    run: number,
    fault: number,
    standby: number,
    dcdc_disabled: number,
    on_load: number,
    fan_pulse: number,
    serated: number,
    sv01: number,
    sv02: number,
    sv04: number,
    louver_open: number,
    dcdc_enable: number,
    power_from_stack: number,
    power_from_external: number,
}

export interface RANGE_CHART_SERIES_FORM {
    x: string,
    y: number[],
}

interface LIFT_0x338_INFO {
    output_power: number,
    output_voltage: number,
    output_current: number,
    anode_pressure: number,
}

interface LIFT_0x358_INFO {
    louver_position: number,
    fan_sp_duty: number,
}

interface LIFT_0x348_INFO {
    outlet_temperature: number,
    inlet_temperature: number,
    dcdc_voltage_set_point: number,
    dcdc_current_limit: number,
}

interface SOAR_0x400_INFO {
    soar_cyclic_counter: number,
    soar_fcpm_state_run: number,
    soar_fcpm_state_sleep: number,
    soar_fcpm_state_dst: number,
    soar_fcpm_state_dc: number,
    soar_tank_level: number,
    soar_battery_voltage: number,
    soar_output_power: number,
    soar_spm_input_power: number,
    soar_battery_power: number,
}

export interface LINE_CHART_SERIES_FROM {
    name: string,
    data: number[][]
}

class LogFileManager {
    private static instance: LogFileManager;

    private _log_file_list:ST_LOG_FILE_INFO[] = [];
    private _log_file_queue:STRUCT_HYDROGEN_LOG_INFO[] = [];

    private _log_file_data = '';

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    constructor() {
        // 
    }

    CommandLogging(raw_data:string, connection_type:STACK_CONN_TYPE) {
        if(connection_type == STACK_CONN_TYPE.UART) {
            // 
        }
        else if(connection_type == STACK_CONN_TYPE.NETWORK) {
            // 
            // console.log(1)
            const str = raw_data;
            let base64decode = '';
    
            try {
                base64decode = atob(str);
            } catch (error) {
                return undefined;
            }
            
            let addr = '';
            let command = '';
            let data = '';
      
            for(let i = 0; i < base64decode.length; ++i) {
                if(i < 2) {
                    addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else if(i < 4) {
                    command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else {
                    data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
            }

            if(addr != '0000')
            console.log(`${addr}${command}${data}`)
            const hex_command = parseInt(command, 16);
            
            if(parseInt("310", 16) <= hex_command && hex_command < (parseInt("310", 16) + 8)) {
                // 
            }
            else if(parseInt("318", 16) <= hex_command && hex_command < (parseInt("318", 16) + 8)) {
                // 
            }
            else if(parseInt("320", 16) <= hex_command && hex_command < (parseInt("320", 16) + 8)) {
                // 
            }
            else if(parseInt("328", 16) <= hex_command && hex_command < (parseInt("328", 16) + 8)) {
                // 
            }
            else if(parseInt("338", 16) <= hex_command && hex_command < (parseInt("338", 16) + 8)) {
                // 
            }
            else if(parseInt("348", 16) <= hex_command && hex_command < (parseInt("348", 16) + 8)) {
                // 
            }
            else if(parseInt("358", 16) <= hex_command && hex_command < (parseInt("358", 16) + 8)) {
                // 
            }
            else if(parseInt("368", 16) <= hex_command && hex_command < (parseInt("368", 16) + 8)) {
                // 
            }
            else if(parseInt("378", 16) <= hex_command && hex_command < (parseInt("378", 16) + 8)) {
                // 
            }
            else if(parseInt("400", 16) <= hex_command && hex_command < (parseInt("400", 16) + 8)) {
                // 
            }
            else {
                console.log(addr, data, command, hex_command);
            }
        
        }
    }

    GetSoar0x400Info(raw_data:string, connection_type:STACK_CONN_TYPE):SOAR_0x400_INFO|undefined {
        // 
        const result:SOAR_0x400_INFO = {
            soar_cyclic_counter: 0,
            soar_fcpm_state_run: 0,
            soar_fcpm_state_sleep: 0,
            soar_fcpm_state_dst: 0,
            soar_fcpm_state_dc: 0,
            soar_tank_level: 0,
            soar_battery_voltage: 0,
            soar_output_power: 0,
            soar_spm_input_power: 0,
            soar_battery_power: 0,
        };

        if(connection_type == STACK_CONN_TYPE.UART) {
            const command = raw_data.split(":")[0].slice(-3);
            const data = raw_data.split(':')[1];
            const splited:string[] = data.split(',');

            if (command == '400' && splited.length == 7) {
                const cyclic_counter = splited[0];
                const fcpm_state = splited[1];
                const hex_to_decimal_state = parseInt(fcpm_state, 16);
                const tank_level = splited[2];
                const battery_voltage = splited[3];
                const output_power = splited[4];
                const spm_input_power = splited[5];
                const battery_power = splited[6];

                if(hex_to_decimal_state == 0) {
                    console.log('00000000000')
                }

                result.soar_cyclic_counter = Number(cyclic_counter);
                result.soar_fcpm_state_run = hex_to_decimal_state & 8 ? 1 : 0;
                result.soar_fcpm_state_sleep = hex_to_decimal_state & 4 ? 1 : 0;
                result.soar_fcpm_state_dst = hex_to_decimal_state & 2 ? 1 : 0;
                result.soar_fcpm_state_dc = hex_to_decimal_state & 1 ? 1 : 0;
                result.soar_tank_level = Number(tank_level);
                result.soar_battery_voltage = Number(battery_voltage);
                result.soar_output_power = Number(output_power);
                result.soar_spm_input_power = Number(spm_input_power);
                result.soar_battery_power = Number(battery_power);
            }
            else {
                return undefined;
            }
        }
        else if(connection_type == STACK_CONN_TYPE.NETWORK) {
            const str = raw_data;
            let base64decode = '';
    
            try {
                base64decode = atob(str);
            } catch (error) {
                return undefined;
            }
            
            let addr = '';
            let command = '';
            let data = '';
      
            for(let i = 0; i < base64decode.length; ++i) {
                if(i < 2) {
                    addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else if(i < 4) {
                    command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else {
                    data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
            }
            const hex_command = parseInt(command, 16);
    
            if(data == '0000000000000000') {
                return undefined;
            }
            
            if(parseInt("400", 16) <= hex_command && hex_command < (parseInt("400", 16) + 8)) {
                console.error('fix me');
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }

        return result;
    }

    GetLift0x368Info(raw_data:string, connection_type:STACK_CONN_TYPE):LIFT_0x368_INFO|undefined {
        const result:LIFT_0x368_INFO = {
            inactive: 0,
            run: 0,
            fault: 0,
            standby: 0,
            dcdc_disabled: 0,
            dcdc_enable: 0,
            fan_pulse: 0,
            louver_open: 0,
            on_load: 0,
            power_from_external: 0,
            power_from_stack: 0,
            serated: 0,
            sv01: 0,
            sv02: 0,
            sv04: 0,
        };

        if(connection_type == STACK_CONN_TYPE.UART) {
            const command = raw_data.split(":")[0].slice(-3);
            const data = raw_data.split(':')[1];

            if (command == '368' && data.length == 24) {
                const state_information = data.substring(0, 8);
                const load_logic = data.substring(8, 16);
                const output_bits = data.substring(16);

                result.inactive = Number(state_information.substring(0, 1));
                result.run = Number(state_information.substring(1, 2));
                result.standby = Number(state_information.substring(2, 3));
                result.fault = Number(state_information.substring(3, 4));
                result.dcdc_disabled = Number(load_logic.substring(0, 1));
                result.on_load = Number(load_logic.substring(1, 2));
                result.fan_pulse = Number(load_logic.substring(2, 3));
                result.serated = Number(load_logic.substring(3, 4));
                result.sv01 = Number(output_bits.substring(0, 1));
                result.sv02 = Number(output_bits.substring(1, 2));
                result.sv04 = Number(output_bits.substring(2, 3));
                result.louver_open = Number(output_bits.substring(3, 4));
                result.dcdc_enable = Number(output_bits.substring(4, 5));
                result.power_from_stack = Number(output_bits.substring(5, 6));
                result.power_from_external = Number(output_bits.substring(6, 7));
            }
            else {
                return undefined;
            }
        }
        else if(connection_type == STACK_CONN_TYPE.NETWORK) {
            const str = raw_data;
            let base64decode = '';
    
            try {
                base64decode = atob(str);
            } catch (error) {
                return undefined;
            }
            
            let addr = '';
            let command = '';
            let data = '';
      
            for(let i = 0; i < base64decode.length; ++i) {
                if(i < 2) {
                    addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else if(i < 4) {
                    command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else {
                    data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
            }
            const hex_command = parseInt(command, 16);
    
            if(data == '0000000000000000') {
                return undefined;
            }
            
            if(parseInt("368", 16) <= hex_command && hex_command < (parseInt("368", 16) + 8)) {
                const state_information = parseInt(data.slice(0, 2), 16).toString(2).padStart(8, '0');
                const load_logic = parseInt(data.slice(2, 4), 16).toString(2).padStart(8, '0');
                const output_bits = parseInt(data.slice(4, 6), 16).toString(2).padStart(8, '0');
    
                result.inactive = Number(state_information.substring(0, 1));
                result.run = Number(state_information.substring(1, 2));
                result.standby = Number(state_information.substring(2, 3));
                result.fault = Number(state_information.substring(3, 4));
    
                result.dcdc_disabled = Number(load_logic.substring(0, 1));
                result.on_load = Number(load_logic.substring(1, 2));
                result.fan_pulse = Number(load_logic.substring(2, 3));
                result.serated = Number(load_logic.substring(3, 4));
    
                result.sv01 = Number(output_bits.substring(0, 1));
                result.sv02 = Number(output_bits.substring(1, 2));
                result.sv04 = Number(output_bits.substring(2, 3));
                result.louver_open = Number(output_bits.substring(3, 4));
                result.dcdc_enable = Number(output_bits.substring(4, 5));
                
                result.power_from_stack = Number(output_bits.substring(5, 6));
                result.power_from_external = Number(output_bits.substring(6, 7));
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }

        return result;
    }

    GetLift0x338Info(raw_data:string, connection_type:STACK_CONN_TYPE):LIFT_0x338_INFO|undefined {
        const result:LIFT_0x338_INFO = {
            output_power: 0,
            output_voltage: 0,
            output_current: 0,
            anode_pressure: 0,
        };

        if(connection_type == STACK_CONN_TYPE.UART) {
            const command = raw_data.split(":")[0].slice(-3);
            const data = raw_data.split(':')[1];

            if (command == '338' && data.split(",").length == 4) {
                const output_power = data.split(",")[0];
                const output_voltage = data.split(",")[1];
                const output_current = data.split(",")[2];
                const anode_pressure = data.split(",")[3];
          
                result.output_power = Number(output_power);
                result.output_voltage = Number(output_voltage);
                result.output_current = Number(output_current);
                result.anode_pressure = Number(anode_pressure);
            }
            else {
                return undefined;
            }
        }
        else if(connection_type == STACK_CONN_TYPE.NETWORK) {
            const str = raw_data;
            let base64decode = '';
    
            try {
                base64decode = atob(str);
            } catch (error) {
                return undefined;
            }
            
            let addr = '';
            let command = '';
            let data = '';
      
            for(let i = 0; i < base64decode.length; ++i) {
                if(i < 2) {
                    addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else if(i < 4) {
                    command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else {
                    data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
            }
            const hex_command = parseInt(command, 16);
    
            if(data == '0000000000000000') {
                return undefined;
            }
    
            if(parseInt("338", 16) <= hex_command && hex_command < (parseInt("338", 16) + 8)) {
                // result.output_power = parseInt(data.slice(0, 4), 16);
                result.output_power = ToInt16(parseInt(data.slice(0, 4), 16));
                result.output_voltage = Math.floor(ToInt16(parseInt(data.slice(4, 8), 16)) *.01);
                result.output_current = Math.floor(ToInt16(parseInt(data.slice(8, 12), 16)) *.01);
                result.anode_pressure = Math.floor(ToInt16(parseInt(data.slice(12), 16)) *.1);
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }

        return result;
    }

    GetLift0x348Info(raw_data:string, connection_type:STACK_CONN_TYPE):LIFT_0x348_INFO|undefined {
        const result:LIFT_0x348_INFO = {
            outlet_temperature: 0,
            inlet_temperature: 0,
            dcdc_current_limit: 0,
            dcdc_voltage_set_point: 0,
        };

        if(connection_type == STACK_CONN_TYPE.UART) {
            const command = raw_data.split(":")[0].slice(-3);
            const data = raw_data.split(':')[1];

            if (command == '348' && data.split(",").length == 4) {
                const outlet_temperature = data.split(",")[0];
                const inlet_temperature = data.split(",")[1];
                const dcdc_voltage_set_point = data.split(",")[2];
                const dcdc_current_limit = data.split(",")[3];
          
                result.outlet_temperature = Number(outlet_temperature);
                result.inlet_temperature = Number(inlet_temperature);
                result.dcdc_voltage_set_point = Number(dcdc_voltage_set_point);
                result.dcdc_current_limit = Number(dcdc_current_limit);
            }
            else {
                return undefined;
            }
        }
        else if(connection_type == STACK_CONN_TYPE.NETWORK) {
            const str = raw_data;
            let base64decode = '';
    
            try {
                base64decode = atob(str);
            } catch (error) {
                return undefined;
            }
            
            let addr = '';
            let command = '';
            let data = '';
      
            for(let i = 0; i < base64decode.length; ++i) {
                if(i < 2) {
                    addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else if(i < 4) {
                    command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else {
                    data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
            }
            const hex_command = parseInt(command, 16);
    
            if(data == '0000000000000000') {
                return undefined;
            }
    
            if(parseInt("348", 16) <= hex_command && hex_command < (parseInt("348", 16) + 8)) {
                result.outlet_temperature = Math.floor(ToInt16(parseInt(data.slice(0, 4), 16)) *.01);
                result.inlet_temperature = Math.floor(ToInt16(parseInt(data.slice(4, 8), 16)) *.01);
                result.dcdc_voltage_set_point = Math.floor(ToInt16(parseInt(data.slice(8, 12), 16)) *.01);
                result.dcdc_current_limit = Math.floor(ToInt16(parseInt(data.slice(12), 16)) *.01);
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }

        return result;
    }

    GetLift0x358Info(raw_data:string, connection_type:STACK_CONN_TYPE):LIFT_0x358_INFO|undefined {
        const result:LIFT_0x358_INFO = {
            louver_position: 0,
            fan_sp_duty: 0,
        };

        if(connection_type == STACK_CONN_TYPE.UART) {
            const command = raw_data.split(":")[0].slice(-3);
            const data = raw_data.split(':')[1];

            if (command == '358' && data.split(",").length == 2) {
                const louver_position = data.split(",")[0];
                const fan_sp_duty = data.split(",")[1];
          
                result.louver_position = Number(louver_position);
                result.fan_sp_duty = Number(fan_sp_duty);
            }
            else {
                return undefined;
            }
        }
        else if(connection_type == STACK_CONN_TYPE.NETWORK) {
            const str = raw_data;
            let base64decode = '';
    
            try {
                base64decode = atob(str);
            } catch (error) {
                return undefined;
            }
            
            let addr = '';
            let command = '';
            let data = '';
      
            for(let i = 0; i < base64decode.length; ++i) {
                if(i < 2) {
                    addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else if(i < 4) {
                    command += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
                else {
                    data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
                }
            }
            const hex_command = parseInt(command, 16);
    
            if(data == '0000000000000000') {
                return undefined;
            }
    
            if(parseInt("358", 16) <= hex_command && hex_command < (parseInt("358", 16) + 8)) {
                result.louver_position = Math.floor(ToInt16(parseInt(data.slice(0, 4), 16)) *.01);
                result.fan_sp_duty = Math.floor(ToInt16(parseInt(data.slice(4, 8), 16)) *.01);
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }

        return result;
    }

    UpdateLogFileData(chunk:number, max_chunk:number, file_data: string, file_name:string) {
        if(chunk == 1) {
            this._log_file_data = '';    
        }
        this._log_file_data += file_data;

        if(chunk == max_chunk) {

            // console.log('whoo lay~~~~')
            const connection_type:STACK_CONN_TYPE = Number(file_name.split('_')[3]);

            const log_lines:string[] = this._log_file_data.split('\r\n');

            const state_data:RANGE_CHART_SERIES_FORM[] = [];
            state_data.push(
                {
                    x: 'Run',
                    y: [],
                },
                {
                    x: 'Inactive',
                    y: [],
                },
                {
                    x: 'Fault',
                    y: [],
                },
                {
                    x: 'Standby',
                    y: [],
                },
                {
                    x: 'DCDC Disabled',
                    y: [],
                },
                {
                    x: 'DCDC Enable',
                    y: [],
                },
                {
                    x: 'On Load',
                    y: [],
                },
                {
                    x: 'Serated',
                    y: [],
                },
                {
                    x: 'SV01',
                    y: [],
                },
                {
                    x: 'SV02',
                    y: [],
                },
                {
                    x: 'SV04',
                    y: [],
                },
                {
                    x: 'Power From Stack',
                    y: [],
                },
                {
                    x: 'Power From External',
                    y: [],
                },
            )
            const fan_pulse_data:RANGE_CHART_SERIES_FORM[] = [];
            fan_pulse_data.push(
                {
                    x: 'Fan Pulse',
                    y: [],
                },
            )
            const louver_open_data:RANGE_CHART_SERIES_FORM[] = [];
            louver_open_data.push(
                {
                    x: 'Louver Open',
                    y: [],
                },
            )
            let inactive_before = 0;
            let run_before = 0;
            let fault_before = 0;
            let standby_before = 0;
            let dcdc_disabled_before = 0;
            let dcdc_enable_before = 0;
            let on_load_before = 0;
            let fan_pulse_before = 0;
            let louver_open_before = 0;
            let serated_before = 0;
            let sv01_before = 0;
            let sv02_before = 0;
            let sv04_before = 0;
            let power_from_stack_before = 0;
            let power_from_external_before = 0;

            let run_st = 0;
            let inactive_st = 0;
            let fault_st = 0;
            let standby_st = 0;
            let dcdc_disabled_st = 0;
            let dcdc_enable_st = 0;
            let on_load_st = 0;
            let fan_pulse_st = 0;
            let louver_open_st = 0;
            let serated_st = 0;
            let sv01_st = 0;
            let sv02_st = 0;
            let sv04_st = 0;
            let power_from_stack_st = 0;
            let power_from_external_st = 0;

            const soar_state_data:RANGE_CHART_SERIES_FORM[] = [];
            soar_state_data.push(
                {
                    x: 'Run',
                    y: [],
                },
                {
                    x: 'Sleep',
                    y: [],
                },
                {
                    x: 'Disable Sleep Timeout',
                    y: [],
                },
                {
                    x: 'Disable Charger',
                    y: [],
                },
            )
            let soar_run_before = 0;
            let soar_sleep_before = 0;
            let soar_dst_before = 0;
            let soar_dc_before = 0;
            
            let soar_run_st = 0;
            let soar_sleep_st = 0;
            let soar_dst_st = 0;
            let soar_dc_st = 0;

            const soar_data:LINE_CHART_SERIES_FROM[] = [];
            soar_data.push({
                name: 'Tank Level',
                data: []
            })
            soar_data.push({
                name: 'Battery Voltage',
                data: []
            })
            soar_data.push({
                name: 'Output Power',
                data: []
            })
            soar_data.push({
                name: 'SPM Input Power',
                data: []
            })
            soar_data.push({
                name: 'Battery Power',
                data: []
            })

            const output_data:LINE_CHART_SERIES_FROM[] = [];
            output_data.push({
                name: 'Output Power',
                data: []
            })
            output_data.push({
                name: 'Output Voltage',
                data: []
            })
            output_data.push({
                name: 'Output Current',
                data: []
            })
            const anode_pressure_data:LINE_CHART_SERIES_FROM[] = [];
            anode_pressure_data.push({
                name: 'Anode Pressure',
                data: []
            })
            const fan_sp_duty_data:LINE_CHART_SERIES_FROM[] = [];
            fan_sp_duty_data.push({
                name: 'Fan SP Duty',
                data: []
            })
            const louver_position_data:LINE_CHART_SERIES_FROM[] = [];
            louver_position_data.push({
                name: 'Louver Position',
                data: []
            })
            const temperature_data:LINE_CHART_SERIES_FROM[] = [];
            temperature_data.push({
                name: 'Outlet',
                data: []
            })
            temperature_data.push({
                name: 'Inlet',
                data: []
            })

            log_lines.forEach((log_line:string, index:number, origin:string[]) => {

                {
                    // Time diff check
                    if(index > 0) {
                        const before_log_timestamp = Number(origin[index-1].split(' : ')[0]);
                        const current_log_timestamp = Number(origin[index].split(' : ')[0]);
                        if((current_log_timestamp - before_log_timestamp) > 1000) {
                            // console.log(`Time diff ${(current_log_timestamp - before_log_timestamp)}`)

                            const finish_timestamp = Number(origin[index-1].split(' : ')[0]);
                            const start_timestamp = Number(origin[index].split(' : ')[0]);

                            // Finish
                            output_data[0].data.push(           [finish_timestamp, 0] );
                            output_data[1].data.push(           [finish_timestamp, 0] );
                            output_data[2].data.push(           [finish_timestamp, 0] );
                            anode_pressure_data[0].data.push(   [finish_timestamp, 0] );
                            temperature_data[0].data.push(      [finish_timestamp, 0] );
                            temperature_data[1].data.push(      [finish_timestamp, 0] );
                            fan_sp_duty_data[0].data.push(      [finish_timestamp, 0] );
                            louver_position_data[0].data.push(  [finish_timestamp, 0] );

                            soar_data[0].data.push(             [finish_timestamp, 0] );
                            soar_data[1].data.push(             [finish_timestamp, 0] );
                            soar_data[2].data.push(             [finish_timestamp, 0] );
                            soar_data[3].data.push(             [finish_timestamp, 0] );
                            soar_data[4].data.push(             [finish_timestamp, 0] );



                            // Start
                            output_data[0].data.push(           [start_timestamp, 0] );
                            output_data[1].data.push(           [start_timestamp, 0] );
                            output_data[2].data.push(           [start_timestamp, 0] );
                            anode_pressure_data[0].data.push(   [start_timestamp, 0] );
                            temperature_data[0].data.push(      [start_timestamp, 0] );
                            temperature_data[1].data.push(      [start_timestamp, 0] );
                            fan_sp_duty_data[0].data.push(      [start_timestamp, 0] );
                            louver_position_data[0].data.push(  [start_timestamp, 0] );

                            soar_data[0].data.push(             [start_timestamp, 0] );
                            soar_data[1].data.push(             [start_timestamp, 0] );
                            soar_data[2].data.push(             [start_timestamp, 0] );
                            soar_data[3].data.push(             [start_timestamp, 0] );
                            soar_data[4].data.push(             [start_timestamp, 0] );

                            if(run_before == 1) {
                                run_before = 0;
                                state_data.push({
                                    x: 'Run',
                                    y: [run_st, finish_timestamp]
                                })
                            }

                            if(inactive_before == 1) {
                                inactive_before = 0;
                                state_data.push({
                                    x: 'Inactive',
                                    y: [inactive_st, finish_timestamp]
                                })
                            }

                            if(fault_before == 1) {
                                fault_before = 0;
                                state_data.push({
                                    x: 'Fault',
                                    y: [fault_st, finish_timestamp]
                                })
                            }

                            if(standby_before == 1) {
                                standby_before = 0;
                                state_data.push({
                                    x: 'Standby',
                                    y: [standby_st, finish_timestamp]
                                })
                            }

                            if(dcdc_disabled_before == 1) {
                                dcdc_disabled_before = 0;
                                state_data.push({
                                    x: 'DCDC Disabled',
                                    y: [dcdc_disabled_st, finish_timestamp]
                                })
                            }

                            if(dcdc_enable_before == 1) {
                                dcdc_enable_before = 0;
                                state_data.push({
                                    x: 'DCDC Enable',
                                    y: [dcdc_enable_st, finish_timestamp]
                                })
                            }

                            if(on_load_before == 1) {
                                on_load_before = 0;
                                state_data.push({
                                    x: 'On Load',
                                    y: [on_load_st, finish_timestamp]
                                })
                            }

                            if(fan_pulse_before == 1) {
                                fan_pulse_before = 0;
                                fan_pulse_data.push({
                                    x: 'Fan Pulse',
                                    y: [fan_pulse_st, finish_timestamp]
                                })
                            }

                            if(louver_open_before == 1) {
                                louver_open_before = 0;
                                louver_open_data.push({
                                    x: 'Louver Open',
                                    y: [louver_open_st, finish_timestamp]
                                })
                            }

                            if(serated_before == 1) {
                                serated_before = 0;
                                state_data.push({
                                    x: 'Serated',
                                    y: [serated_st, finish_timestamp]
                                })
                            }

                            if(sv01_before == 1) {
                                sv01_before = 0;
                                state_data.push({
                                    x: 'SV01',
                                    y: [sv01_st, finish_timestamp]
                                })
                            }

                            if(sv02_before == 1) {
                                sv02_before = 0;
                                state_data.push({
                                    x: 'SV02',
                                    y: [sv02_st, finish_timestamp]
                                })
                            }

                            if(sv04_before == 1) {
                                sv04_before = 0;
                                state_data.push({
                                    x: 'SV04',
                                    y: [sv04_st, finish_timestamp]
                                })
                            }

                            if(power_from_stack_before == 1) {
                                power_from_stack_before = 0;
                                state_data.push({
                                    x: 'Power From Stack',
                                    y: [power_from_stack_st, finish_timestamp]
                                })
                            }

                            if(power_from_external_before == 1) {
                                power_from_external_before = 0;
                                state_data.push({
                                    x: 'Power From External',
                                    y: [power_from_external_st, finish_timestamp]
                                })
                            }

                            // Soar
                            if(soar_run_before == 1) {
                                soar_run_before = 0;
                                soar_state_data.push({
                                    x: 'Run',
                                    y: [soar_run_st, finish_timestamp]
                                })
                            }

                            if(soar_sleep_before == 1) {
                                soar_sleep_before = 0;
                                soar_state_data.push({
                                    x: 'Sleep',
                                    y: [soar_sleep_st, finish_timestamp]
                                })
                            }

                            if(soar_dst_before == 1) {
                                soar_dst_before = 0;
                                soar_state_data.push({
                                    x: 'Disable Sleep Timeout',
                                    y: [soar_sleep_st, finish_timestamp]
                                })
                            }

                            if(soar_dc_before == 1) {
                                soar_dc_before = 0;
                                soar_state_data.push({
                                    x: 'Disable Charger',
                                    y: [soar_dc_st, finish_timestamp]
                                })
                            }
                            
                        }
                    }
                }

                const splited:string[] = log_line.split(' : ');
                if(splited.length == 2) {
                    this.CommandLogging(splited[1], connection_type);
                    const info_0x368:LIFT_0x368_INFO|undefined = this.GetLift0x368Info(splited[1], connection_type);
                    if(info_0x368 != undefined) {
                        if(info_0x368.run != run_before && info_0x368.run == 1) {
                            run_st = Number(splited[0]);
                            run_before = 1;
                        }
                        else if(info_0x368.run != run_before && info_0x368.run == 0) {
                            run_before = 0;
                            state_data.push({
                                x: 'Run',
                                y: [run_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.inactive != inactive_before && info_0x368.inactive == 1) {
                            inactive_st = Number(splited[0]);
                            inactive_before = 1;
                        }
                        else if(info_0x368.inactive != inactive_before && info_0x368.inactive == 0) {
                            inactive_before = 0;
                            state_data.push({
                                x: 'Inactive',
                                y: [inactive_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.fault != fault_before && info_0x368.fault == 1) {
                            fault_st = Number(splited[0]);
                            fault_before = 1;
                        }
                        else if(info_0x368.fault != fault_before && info_0x368.fault == 0) {
                            fault_before = 0;
                            state_data.push({
                                x: 'Fault',
                                y: [fault_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.standby != standby_before && info_0x368.standby == 1) {
                            standby_st = Number(splited[0]);
                            standby_before = 1;
                        }
                        else if(info_0x368.standby != standby_before && info_0x368.standby == 0) {
                            standby_before = 0;
                            state_data.push({
                                x: 'Standby',
                                y: [standby_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.dcdc_disabled != dcdc_disabled_before && info_0x368.dcdc_disabled == 1) {
                            dcdc_disabled_st = Number(splited[0]);
                            dcdc_disabled_before = 1;
                        }
                        else if(info_0x368.dcdc_disabled != dcdc_disabled_before && info_0x368.dcdc_disabled == 0) {
                            dcdc_disabled_before = 0;
                            state_data.push({
                                x: 'DCDC Disabled',
                                y: [dcdc_disabled_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.dcdc_enable != dcdc_enable_before && info_0x368.dcdc_enable == 1) {
                            dcdc_enable_st = Number(splited[0]);
                            dcdc_enable_before = 1;
                        }
                        else if(info_0x368.dcdc_enable != dcdc_enable_before && info_0x368.dcdc_enable == 0) {
                            dcdc_enable_before = 0;
                            state_data.push({
                                x: 'DCDC Enable',
                                y: [dcdc_enable_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.on_load != on_load_before && info_0x368.on_load == 1) {
                            on_load_st = Number(splited[0]);
                            on_load_before = 1;
                        }
                        else if(info_0x368.on_load != on_load_before && info_0x368.on_load == 0) {
                            on_load_before = 0;
                            state_data.push({
                                x: 'On Load',
                                y: [on_load_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.fan_pulse != fan_pulse_before && info_0x368.fan_pulse == 1) {
                            fan_pulse_st = Number(splited[0]);
                            fan_pulse_before = 1;
                        }
                        else if(info_0x368.fan_pulse != fan_pulse_before && info_0x368.fan_pulse == 0) {
                            fan_pulse_before = 0;
                            fan_pulse_data.push({
                                x: 'Fan Pulse',
                                y: [fan_pulse_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.louver_open != louver_open_before && info_0x368.louver_open == 1) {
                            louver_open_st = Number(splited[0]);
                            louver_open_before = 1;
                        }
                        else if(info_0x368.louver_open != louver_open_before && info_0x368.louver_open == 0) {
                            louver_open_before = 0;
                            louver_open_data.push({
                                x: 'Louver Open',
                                y: [louver_open_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.serated != serated_before && info_0x368.serated == 1) {
                            serated_st = Number(splited[0]);
                            serated_before = 1;
                        }
                        else if(info_0x368.serated != serated_before && info_0x368.serated == 0) {
                            serated_before = 0;
                            state_data.push({
                                x: 'Serated',
                                y: [serated_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.sv01 != sv01_before && info_0x368.sv01 == 1) {
                            sv01_st = Number(splited[0]);
                            sv01_before = 1;
                        }
                        else if(info_0x368.sv01 != sv01_before && info_0x368.sv01 == 0) {
                            sv01_before = 0;
                            state_data.push({
                                x: 'SV01',
                                y: [sv01_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.sv02 != sv02_before && info_0x368.sv02 == 1) {
                            sv02_st = Number(splited[0]);
                            sv02_before = 1;
                        }
                        else if(info_0x368.sv02 != sv02_before && info_0x368.sv02 == 0) {
                            sv02_before = 0;
                            state_data.push({
                                x: 'SV02',
                                y: [sv02_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.sv04 != sv04_before && info_0x368.sv04 == 1) {
                            sv04_st = Number(splited[0]);
                            sv04_before = 1;
                        }
                        else if(info_0x368.sv04 != sv04_before && info_0x368.sv04 == 0) {
                            sv04_before = 0;
                            state_data.push({
                                x: 'SV04',
                                y: [sv04_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.power_from_stack != power_from_stack_before && info_0x368.power_from_stack == 1) {
                            power_from_stack_st = Number(splited[0]);
                            power_from_stack_before = 1;
                        }
                        else if(info_0x368.power_from_stack != power_from_stack_before && info_0x368.power_from_stack == 0) {
                            power_from_stack_before = 0;
                            state_data.push({
                                x: 'Power From Stack',
                                y: [power_from_stack_st, Number(splited[0])]
                            })
                        }

                        if(info_0x368.power_from_external != power_from_external_before && info_0x368.power_from_external == 1) {
                            power_from_external_st = Number(splited[0]);
                            power_from_external_before = 1;
                        }
                        else if(info_0x368.power_from_external != power_from_external_before && info_0x368.power_from_external == 0) {
                            power_from_external_before = 0;
                            state_data.push({
                                x: 'Power From External',
                                y: [power_from_external_st, Number(splited[0])]
                            })
                        }
                    }

                    ////////////////////

                    const info_0x338:LIFT_0x338_INFO|undefined = this.GetLift0x338Info(splited[1], connection_type);
                    if(info_0x338 != undefined) {
                        output_data[0].data.push( [Number(splited[0]), info_0x338.output_power ] );
                        output_data[1].data.push( [Number(splited[0]), info_0x338.output_voltage] );
                        output_data[2].data.push( [Number(splited[0]), info_0x338.output_current] );
                        anode_pressure_data[0].data.push( [Number(splited[0]), info_0x338.anode_pressure] );
                    }

                    const info_0x348:LIFT_0x348_INFO|undefined = this.GetLift0x348Info(splited[1], connection_type);
                    if(info_0x348 != undefined) {
                        temperature_data[0].data.push( [Number(splited[0]), info_0x348.outlet_temperature] );
                        temperature_data[1].data.push( [Number(splited[0]), info_0x348.inlet_temperature] );
                    }

                    const info_0x358:LIFT_0x358_INFO|undefined = this.GetLift0x358Info(splited[1], connection_type);
                    if(info_0x358 != undefined) {

                        fan_sp_duty_data[0].data.push( [Number(splited[0]), info_0x358.fan_sp_duty] );
                        louver_position_data[0].data.push( [Number(splited[0]), info_0x358.louver_position] );
                    }

                    const info_0x400:SOAR_0x400_INFO|undefined = this.GetSoar0x400Info(splited[1], connection_type);
                    if(info_0x400 != undefined) {

                        soar_data[0].data.push( [Number(splited[0]), info_0x400.soar_tank_level ] );
                        soar_data[1].data.push( [Number(splited[0]), info_0x400.soar_output_power] );
                        soar_data[2].data.push( [Number(splited[0]), info_0x400.soar_battery_voltage] );
                        soar_data[3].data.push( [Number(splited[0]), info_0x400.soar_spm_input_power] );
                        soar_data[4].data.push( [Number(splited[0]), info_0x400.soar_battery_power] );

                        if(info_0x400.soar_fcpm_state_run != soar_run_before && info_0x400.soar_fcpm_state_run == 1) {
                            soar_run_st = Number(splited[0]);
                            soar_run_before = 1;
                        }
                        else if(info_0x400.soar_fcpm_state_run != soar_run_before && info_0x400.soar_fcpm_state_run == 0) {
                            soar_run_before = 0;
                            soar_state_data.push({
                                x: 'Run',
                                y: [soar_run_st, Number(splited[0])]
                            })
                        }

                        if(info_0x400.soar_fcpm_state_sleep != soar_sleep_before && info_0x400.soar_fcpm_state_sleep == 1) {
                            soar_sleep_st = Number(splited[0]);
                            soar_sleep_before = 1;
                        }
                        else if(info_0x400.soar_fcpm_state_sleep != soar_sleep_before && info_0x400.soar_fcpm_state_sleep == 0) {
                            soar_sleep_before = 0;
                            soar_state_data.push({
                                x: 'Sleep',
                                y: [soar_sleep_st, Number(splited[0])]
                            })
                        }

                        if(info_0x400.soar_fcpm_state_dst != soar_dst_before && info_0x400.soar_fcpm_state_dst == 1) {
                            soar_dst_st = Number(splited[0]);
                            soar_dst_before = 1;
                        }
                        else if(info_0x400.soar_fcpm_state_dst != soar_dst_before && info_0x400.soar_fcpm_state_dst == 0) {
                            soar_dst_before = 0;
                            soar_state_data.push({
                                x: 'Disable Sleep Timeout',
                                y: [soar_dst_st, Number(splited[0])]
                            })
                        }

                        if(info_0x400.soar_fcpm_state_dc != soar_dc_before && info_0x400.soar_fcpm_state_dc == 1) {
                            soar_dc_st = Number(splited[0]);
                            soar_dc_before = 1;
                        }
                        else if(info_0x400.soar_fcpm_state_dc != soar_dc_before && info_0x400.soar_fcpm_state_dc == 0) {
                            soar_dc_before = 0;
                            soar_state_data.push({
                                x: 'Disable Charger',
                                y: [soar_dc_st, Number(splited[0])]
                            })
                        }
                    }
                }

                {
                    // 끝단 처리
                    // 마지막 Line은 개행뿐이라 index에 2를 더했다..
                    if((index + 2) == origin.length) {
                        const finish_timestamp = Number(log_line.split(' : ')[0]);

                        if(run_before == 1) {
                            run_before = 0;
                            state_data.push({
                                x: 'Run',
                                y: [run_st, finish_timestamp]
                            })
                        }

                        if(inactive_before == 1) {
                            inactive_before = 0;
                            state_data.push({
                                x: 'Inactive',
                                y: [inactive_st, finish_timestamp]
                            })
                        }

                        if(fault_before == 1) {
                            fault_before = 0;
                            state_data.push({
                                x: 'Fault',
                                y: [fault_st, finish_timestamp]
                            })
                        }

                        if(standby_before == 1) {
                            standby_before = 0;
                            state_data.push({
                                x: 'Standby',
                                y: [standby_st, finish_timestamp]
                            })
                        }

                        if(dcdc_disabled_before == 1) {
                            dcdc_disabled_before = 0;
                            state_data.push({
                                x: 'DCDC Disabled',
                                y: [dcdc_disabled_st, finish_timestamp]
                            })
                        }

                        if(dcdc_enable_before == 1) {
                            dcdc_enable_before = 0;
                            state_data.push({
                                x: 'DCDC Enable',
                                y: [dcdc_enable_st, finish_timestamp]
                            })
                        }

                        if(on_load_before == 1) {
                            on_load_before = 0;
                            state_data.push({
                                x: 'On Load',
                                y: [on_load_st, finish_timestamp]
                            })
                        }

                        if(fan_pulse_before == 1) {
                            fan_pulse_before = 0;
                            fan_pulse_data.push({
                                x: 'Fan Pulse',
                                y: [fan_pulse_st, finish_timestamp]
                            })
                        }

                        if(louver_open_before == 1) {
                            louver_open_before = 0;
                            louver_open_data.push({
                                x: 'Louver Open',
                                y: [louver_open_st, finish_timestamp]
                            })
                        }

                        if(serated_before == 1) {
                            serated_before = 0;
                            state_data.push({
                                x: 'Serated',
                                y: [serated_st, finish_timestamp]
                            })
                        }

                        if(sv01_before == 1) {
                            sv01_before = 0;
                            state_data.push({
                                x: 'SV01',
                                y: [sv01_st, finish_timestamp]
                            })
                        }

                        if(sv02_before == 1) {
                            sv02_before = 0;
                            state_data.push({
                                x: 'SV02',
                                y: [sv02_st, finish_timestamp]
                            })
                        }

                        if(sv04_before == 1) {
                            sv04_before = 0;
                            state_data.push({
                                x: 'SV04',
                                y: [sv04_st, finish_timestamp]
                            })
                        }

                        if(power_from_stack_before == 1) {
                            power_from_stack_before = 0;
                            state_data.push({
                                x: 'Power From Stack',
                                y: [power_from_stack_st, finish_timestamp]
                            })
                        }

                        if(power_from_external_before == 1) {
                            power_from_external_before = 0;
                            state_data.push({
                                x: 'Power From External',
                                y: [power_from_external_st, finish_timestamp]
                            })
                        }

                        // Soar
                        if(soar_run_before == 1) {
                            soar_run_before = 0;
                            soar_state_data.push({
                                x: 'Run',
                                y: [soar_run_st, finish_timestamp]
                            })
                        }

                        if(soar_sleep_before == 1) {
                            soar_sleep_before = 0;
                            soar_state_data.push({
                                x: 'Sleep',
                                y: [soar_sleep_st, finish_timestamp]
                            })
                        }

                        if(soar_dst_before == 1) {
                            soar_dst_before = 0;
                            soar_state_data.push({
                                x: 'Disable Sleep Timeout',
                                y: [soar_sleep_st, finish_timestamp]
                            })
                        }

                        if(soar_dc_before == 1) {
                            soar_dc_before = 0;
                            soar_state_data.push({
                                x: 'Disable Charger',
                                y: [soar_dc_st, finish_timestamp]
                            })
                        }
                    }
                }
            });

            store.commit('SetReportState', state_data);
            store.commit('SetReportFanPulse', fan_pulse_data);
            store.commit('SetReportLouverOpen', louver_open_data);

            store.commit('SetReportOutput', output_data);
            store.commit('SetReportAnodePressure', anode_pressure_data);
            store.commit('SetReportFanSpDuty', fan_sp_duty_data);
            store.commit('SetReportLouverPosition', louver_position_data);
            store.commit('SetReportTemperature', temperature_data);

            store.commit('SetReportSoarState', soar_state_data);
            store.commit('SetReportSoarData', soar_data);
        }
    }

    UpdateLogFileList(chunk:number, max_chunk:number, file_list: STRUCT_HYDROGEN_LOG_INFO[]) {
        this._log_file_queue = this._log_file_queue.concat(file_list);

        if(chunk == max_chunk) {

            const tmp_list:ST_LOG_FILE_INFO[] = [];
            let idx = 0;

            this._log_file_queue.forEach((log_file:STRUCT_HYDROGEN_LOG_INFO) => {
                const file_infos = log_file._file_name.split('_');
                if(file_infos.length == 17) {

                    tmp_list.push(
                        {
                            idx: ++idx,
                            stack_serial: file_infos[0],
                            device_serial: file_infos[1], 
                            model_type: Number(file_infos[2]),
                            connection_type: Number(file_infos[3]),
                            connect_datetime: 
                            new Date(
                                `${file_infos[4]}-${file_infos[5]}-${file_infos[6]} ${file_infos[7]}:${file_infos[8]}:${file_infos[9]}`
                            ),
                            create_datetime: 
                            new Date(
                                `${file_infos[10]}-${file_infos[11]}-${file_infos[12]} ${file_infos[13]}:${file_infos[14]}:${file_infos[15]}`
                            ),
                            file_index: Number(file_infos[16].split('.')[0]),
                            file_name: log_file._file_name,
                        }
                    )

                }
                else {
                    console.log('fix me', file_infos.length);
                }
            });

            this._log_file_list = tmp_list;

            store.commit('SetLogFiles', this._log_file_list);

            this._log_file_queue = [];
        }
    }

    GetLogFileRawData() {
        return this._log_file_data;
    }
}

export default LogFileManager;

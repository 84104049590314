<template>
  <liquid-detail-component :liquid_serial="liquid_serial"/>
</template>

<script lang="ts">
  import Vue from 'vue'
  import LiquidDetailComponent from '../components/LiquidDetail.vue'

  export default Vue.extend({
    name: 'ReportView',

    props: {
      liquid_serial: {
        type: String,
      }
    },

    components: {
      LiquidDetailComponent,
    },
  })
</script>

<template>
  <div class="bg" style="width: 100vw; height: 100vh;">
    <div style="width: 100vw; height: 6vh;">
      <v-row class="pa-0 ma-0" align="center" justify="center" style="height: 6vh;">
        <v-col class="pa-0 ma-0">
          <v-img src="@/assets/hogreen-logo.png" :width="130" v-on:click="OnClickGoHome" style="cursor: pointer;"></v-img>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: center;">
          <h1><strong>Liquid Generator Report</strong></h1>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: right;">
          <v-btn small @click="OnClickGoHome"><v-icon small>mdi-home</v-icon>home</v-btn>
          &nbsp;
          <v-btn small @click="OnClickGoReport"><v-icon small>mdi-chart-line</v-icon>report</v-btn>
          &nbsp;
          <v-chip small label>
            <v-icon small>mdi-account</v-icon>{{ store_auth_id }}<v-btn plain small @click="OnClickLogout"><v-icon small>mdi-logout</v-icon>logout</v-btn>
          </v-chip>
        </v-col>
      </v-row>
    </div>

    <v-row class="pa-0 ma-0" style="width: 100vw; height: 6vh;">
      <v-row class="pa-0 ma-0" align-content="center" justify="end" style="height: 6vh;">
        <v-col cols="auto"><v-combobox label="serial" outlined hide-details dense v-model="selected_liquid" :items="liquids" :item-text="'liquid_serial'" clearable></v-combobox></v-col>
        <v-col cols="auto"><v-text-field class="secondary" type="datetime-local" dark dense v-model="st" outlined solo hide-details></v-text-field></v-col>
        <v-col cols="auto"><v-text-field class="secondary" type="datetime-local" dark dense v-model="et" outlined solo hide-details></v-text-field></v-col>
        <v-col cols="auto" style="display: flex; align-items: center; justify-content: end;"><v-btn color="primary" @click="OnClickSearch">search</v-btn></v-col>
      </v-row>
    </v-row>

    <v-row class="pa-0 ma-0" style="width: 100vw; height: 88vh; overflow-y: scroll;">

      <v-col cols="4">
        <!-- <v-card> -->
          <apexchart ref="chart_lhr" :height="200" :options="line_chart_options" :series="lhr_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="4">
        <!-- <v-card> -->
          <apexchart ref="chart_lhrkw" :height="200" :options="line_chart_options" :series="lhrkw_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="4">
        <!-- <v-card> -->
          <apexchart ref="chart_kpd" :height="200" :options="line_chart_options" :series="kpd_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="6">
        <!-- <v-card> -->
          <apexchart ref="chart_gh2inlet" :height="200" :options="line_chart_options" :series="gh2inlet_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="6">
        <!-- <v-card> -->
          <apexchart ref="chart_gh2vent" :height="200" :options="line_chart_options" :series="gh2vent_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="6">
        <!-- <v-card> -->
          <apexchart ref="chart_vacuum" :height="200" :options="line_chart_options" :series="vacuum_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="6">
        <!-- <v-card> -->
          <apexchart ref="chart_outertank" :height="200" :options="line_chart_options" :series="outertank_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="6">
        <!-- <v-card> -->
          <apexchart ref="chart_mfcgs" :height="200" :options="line_chart_options" :series="mfcgs_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="6">
        <!-- <v-card> -->
          <apexchart ref="chart_pv" :height="200" :options="line_chart_options" :series="pv_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="3">
        <!-- <v-card> -->
          <apexchart ref="chart_cit" :height="200" :options="line_chart_options" :series="cit_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="3">
        <!-- <v-card> -->
          <apexchart ref="chart_cot" :height="200" :options="line_chart_options" :series="cot_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="6">
        <!-- <v-card> -->
          <apexchart ref="chart_cs" :height="200" :options="line_chart_options" :series="cs_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="12">
        <!-- <v-card> -->
          <apexchart ref="chart_ts" :height="300" :options="line_chart_options" :series="ts_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

      <v-col cols="12">
        <!-- <v-card> -->
          <apexchart ref="chart_total" :height="800" :options="line_chart_options" :series="total_series"></apexchart>
        <!-- </v-card> -->
      </v-col>

    </v-row>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import LIQUID from '@/services/liquid/Liquid';
import LiquidManager from '@/services/liquid/LiquidManager';
import { STRUCT_LIQUID_STATE } from '@/services/websocket/WebsocketInterface';
import store from '@/store';
import axios from 'axios';
import moment from 'moment';
import Vue, { computed } from 'vue'
import VueApexCharts from 'vue-apexcharts'

interface LOG_INFO {
  _datetime: string,
  _serial: string,
  _liquid_state: STRUCT_LIQUID_STATE,
}

export default Vue.extend({
  name: 'StackDetail',

  props: {
    liquid_serial: {
      type: String,
      default: '',
    },
  },

  components: {
    apexchart: VueApexCharts
  },

  data() {
    const liquid:LIQUID|undefined = LiquidManager.getInstance().GetLiquidFromSerial(this.liquid_serial);

    const ts_1_data = { name: 'TS01' as string, data: [] as number[][], }
    const ts_2_data = { name: 'TS02' as string, data: [] as number[][], }
    const ts_3_data = { name: 'TS03' as string, data: [] as number[][], }
    const ts_4_data = { name: 'TS04' as string, data: [] as number[][], }
    const ts_5_data = { name: 'TS05' as string, data: [] as number[][], }
    const ts_6_data = { name: 'TS06' as string, data: [] as number[][], }
    const ts_7_data = { name: 'TS07' as string, data: [] as number[][], }
    const ts_8_data = { name: 'TS08' as string, data: [] as number[][], }
    const ts_10_data = { name: 'TS10' as string, data: [] as number[][], }

    const lhr_data = { name: 'Real-time liquefaction rate(L/hr)' as string, data: [] as number[][], }
    const lhrkw_data = { name: 'Liquefaction rate per kW(l/hr/kW)' as string, data: [] as number[][], }
    const kpd_data = { name: 'Liquefaction Kg per Day(kg/day)' as string, data: [] as number[][], }

    const gh2inlet_data = { name: 'GH2 Inlet(barA)' as string, data: [] as number[][], }
    const gh2vent_data = { name: 'GH2 Vent(bar)' as string, data: [] as number[][], }

    const vacuum_data = { name: 'Vacuum(mBar)' as string, data: [] as number[][], }
    const outertank_data = { name: 'Outer Tank(mBar)' as string, data: [] as number[][], }

    const mfcgs_data = { name: 'MFC Mass Flow(SLPM)' as string, data: [] as number[][], }
    const pv_data = { name: 'Positioner Valve(%)' as string, data: [] as number[][], }
    const cit_data = { name: 'Coolant In Temp(℃)' as string, data: [] as number[][], }
    const cot_data = { name: 'Coolant Out Temp(℃)' as string, data: [] as number[][], }

    const cs_data = { name: 'Cooler Status' as string, data: [] as number[][], }

    return {
      liquids: [] as LIQUID[],
      selected_liquid: liquid as LIQUID|undefined,
      st: moment().add(-5, 'minute').format('YYYY-MM-DDTHH:mm:ss') as string,
      et: moment().add(0, 'hour').format('YYYY-MM-DDTHH:mm:ss') as string,

      total_series: [
        ts_1_data,
        ts_2_data,
        ts_3_data,
        ts_4_data,
        ts_5_data,
        ts_6_data,
        ts_7_data,
        ts_8_data,
        ts_10_data,
        lhr_data,
        lhrkw_data,
        kpd_data,
        gh2inlet_data,
        gh2vent_data,
        vacuum_data,
        outertank_data,
        mfcgs_data,
        pv_data,
        cit_data,
        cot_data,
        cs_data,
      ],

      ts_1_data,
      ts_2_data,
      ts_3_data,
      ts_4_data,
      ts_5_data,
      ts_6_data,
      ts_7_data,
      ts_8_data,
      ts_10_data,
      ts_series: [
        ts_1_data,
        ts_2_data,
        ts_3_data,
        ts_4_data,
        ts_5_data,
        ts_6_data,
        ts_7_data,
        ts_8_data,
        ts_10_data,
      ],

      lhr_data,
      lhr_series: [
        lhr_data,
      ],

      lhrkw_data,
      lhrkw_series: [
        lhrkw_data,
      ],

      kpd_data,
      kpd_series: [
        kpd_data,
      ],

      gh2inlet_data,
      gh2inlet_series: [
        gh2inlet_data,
      ],

      gh2vent_data,
      gh2vent_series: [
        gh2vent_data,
      ],

      vacuum_data,
      vacuum_series: [
        vacuum_data,
      ],

      outertank_data,
      outertank_series: [
        outertank_data,
      ],

      mfcgs_data,
      mfcgs_series: [
        mfcgs_data,
      ],

      pv_data,
      pv_series: [
        pv_data,
      ],

      cit_data,
      cit_series: [
        cit_data,
      ],

      cot_data,
      cot_series: [
        cot_data,
      ],

      cs_data,
      cs_series: [
        cs_data,
      ],

      line_chart_options: {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              // download: false,
            },
            export: {
              csv: {
                dateFormatter: function (value: number) {
                  return new Date(value).toISOString()
                  // return moment(value).format('YYYY/MM/DD hh:mm:ss a')
                }
              }
            },
          },
          foreColor: '#ddd',
          background: '#00000000',
          animations: {
            enabled: true,
          },
        },
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#FF00FF'],
        // colors: ['red', 'orange', 'yellow', 'green', 'blue', 'navy', 'purple', 'pink', 'white'],
        stroke: {
          width: 2,
          curve: 'stepline'
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        theme: {
          mode: 'dark',
          palette: 'palette10', 
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            formatter: function (value: number) {
              return moment(value).format('a hh:mm:ss')
            }
          },
        },
        legend: {
          show: true,
          position: 'top',
          showForSingleSeries: true,
          horizontalAlign: 'left',
        },
      },
    };
  },

  mounted() {
    //  
  },

  setup() {
    const store_auth_id = computed<string>(() => store.getters.GetAuthId);
    const store_liquids = computed<LIQUID[]>(() => store.getters.GetLiquids);
    return { store_auth_id, store_liquids };
  },

  watch: {
    store_liquids: {
      handler(val, oldVal) {
        this.liquids = val;
      },
    },
  },
  
  beforeUpdate() {
      // console.log('update')
  },

  methods: {
    OnClickGoHome() {
      router.push({name: 'home'});
    },
    OnClickGoReport() {
      router.push({name: 'report'});
    },
    OnClickLogout() {
      window.location.reload();
    },
    OnClickSearch() {
      if(this.selected_liquid) {
        axios.get(`/liquid/${this.selected_liquid.Get().liquid_serial}/${new Date(this.st).toISOString()}/${new Date(this.et).toISOString()}`).then((res) => {
          if(res.data.state == true) {
            this.ts_1_data.data = [];
            this.ts_2_data.data = [];
            this.ts_3_data.data = [];
            this.ts_4_data.data = [];
            this.ts_5_data.data = [];
            this.ts_6_data.data = [];
            this.ts_7_data.data = [];
            this.ts_8_data.data = [];
            this.ts_10_data.data = [];

            this.lhr_data.data = [];
            this.lhrkw_data.data = [];
            this.kpd_data.data = [];

            this.gh2inlet_data.data = [];
            this.gh2vent_data.data = [];

            this.vacuum_data.data = [];
            this.outertank_data.data = [];

            this.mfcgs_data.data = [];
            this.pv_data.data = [];
            this.cit_data.data = [];
            this.cot_data.data = [];

            this.cs_data.data = [];

            res.data.data.forEach((log_info:LOG_INFO) => {
              const datetime = new Date(log_info._datetime).getTime()
              const liquid_state:STRUCT_LIQUID_STATE = log_info._liquid_state;
              this.ts_1_data.data.push([datetime, liquid_state._temperature_1])
              this.ts_2_data.data.push([datetime, liquid_state._temperature_2])
              this.ts_3_data.data.push([datetime, liquid_state._temperature_3])
              this.ts_4_data.data.push([datetime, liquid_state._temperature_4])
              this.ts_5_data.data.push([datetime, liquid_state._temperature_5])
              this.ts_6_data.data.push([datetime, liquid_state._temperature_6])
              this.ts_7_data.data.push([datetime, liquid_state._temperature_7])
              this.ts_8_data.data.push([datetime, liquid_state._temperature_8])
              this.ts_10_data.data.push([datetime, liquid_state._temperature_10])

              this.lhr_data.data.push([datetime, liquid_state._lhr])
              this.lhrkw_data.data.push([datetime, liquid_state._lhrkw])
              this.kpd_data.data.push([datetime, liquid_state._kg_per_day])

              this.gh2inlet_data.data.push([datetime, liquid_state._gh2_inlet])
              this.gh2vent_data.data.push([datetime, liquid_state._gh2_vent])

              this.vacuum_data.data.push([datetime, liquid_state._vacuum])
              this.outertank_data.data.push([datetime, liquid_state._outer_tank_vacuum])

              this.mfcgs_data.data.push([datetime, liquid_state._mfc_gs])
              this.pv_data.data.push([datetime, liquid_state._positioner_valve])
              this.cit_data.data.push([datetime, liquid_state._coolant_in_temp])
              this.cot_data.data.push([datetime, liquid_state._coolant_out_temp])

              this.cs_data.data.push([datetime, liquid_state._cooler_status])
            });

            let chart: any;
            chart = this.$refs.chart_ts;
            if (chart) chart.updateSeries(this.ts_series, true)

            chart = this.$refs.chart_lhr;
            if (chart) chart.updateSeries(this.lhr_series, true)

            chart = this.$refs.chart_lhrkw;
            if (chart) chart.updateSeries(this.lhrkw_series, true)

            chart = this.$refs.chart_kpd;
            if (chart) chart.updateSeries(this.kpd_series, true)

            chart = this.$refs.chart_gh2inlet;
            if (chart) chart.updateSeries(this.gh2inlet_series, true)

            chart = this.$refs.chart_gh2vent;
            if (chart) chart.updateSeries(this.gh2vent_series, true)

            chart = this.$refs.chart_vacuum;
            if (chart) chart.updateSeries(this.vacuum_series, true)

            chart = this.$refs.chart_outertank;
            if (chart) chart.updateSeries(this.outertank_series, true)

            chart = this.$refs.chart_mfcgs;
            if (chart) chart.updateSeries(this.mfcgs_series, true)

            chart = this.$refs.chart_pv;
            if (chart) chart.updateSeries(this.pv_series, true)

            chart = this.$refs.chart_cit;
            if (chart) chart.updateSeries(this.cit_series, true)

            chart = this.$refs.chart_cot;
            if (chart) chart.updateSeries(this.cot_series, true)

            chart = this.$refs.chart_cs;
            if (chart) chart.updateSeries(this.cs_series, true)


            chart = this.$refs.chart_total;
            if (chart) chart.updateSeries(this.total_series, true)
          }
        });
      }
      
    },
  }
})
</script>

<style scoped>
.bg {
  background-image: linear-gradient(
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),url(@/assets/h2img.png);
  background-position: right bottom;
}
</style>

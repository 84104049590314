import Vue from 'vue'
import VueRouter, { RouteConfig, Route, NavigationGuardNext } from 'vue-router'
import DashBoardView from '../views/DashBoardView.vue'
import StackDetailView from '../views/StackDetailView.vue'
import DeviceDetailView from '../views/DeviceDetailView.vue'
import LoginView from '../views/LoginView.vue'
import ReportView from '../views/ReportView.vue'
import LiquidReportView from '../views/LiquidReportView.vue'
import LiquidDetailView from '../views/LiquidDetailView.vue'
import store from '@/store'

Vue.use(VueRouter)

const authGuard = (to:Route, from:Route, next:NavigationGuardNext) => {
  if (store.getters.GetAuthState) {
    return next()
  }
  else {
    return next('/auth')
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/report',
    name: 'report',
    component: ReportView,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: '/stack/:stack_serial',
    name: 'stack',
    component: StackDetailView,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: '/device/:device_serial',
    name: 'device',
    component: DeviceDetailView,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: '/lqr',
    name: 'lqr',
    component: LiquidReportView,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: '/lqd',
    name: 'lqd',
    component: LiquidDetailView,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: '/',
    name: 'home',
    component: DashBoardView,
    beforeEnter: authGuard,
  },
  {
    path: '/auth',
    name: 'auth',
    component: LoginView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="bg" style="width: 100vw; height: 100vh;">
    <div style="width: 100vw; height: 6vh;">
      <v-row class="pa-0 ma-0" align="center" justify="center" style="height: 6vh;">
        <v-col class="pa-0 ma-0">
          <v-img src="@/assets/hogreen-logo.png" :width="130" v-on:click="OnClickGoHome" style="cursor: pointer;"></v-img>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: center;">
          <h1><strong>{{ device_manager.GetDeviceFromDeviceSerial(device_serial)?.Get().device_index_alias }}</strong></h1>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: right;">
          <v-btn small @click="OnClickGoHome"><v-icon small>mdi-home</v-icon>home</v-btn>
          &nbsp;
          <v-btn small @click="OnClickGoReport"><v-icon small>mdi-chart-line</v-icon>report</v-btn>
          &nbsp;
          <v-chip small label>
            <v-icon small>mdi-account</v-icon>{{ store_auth_id }}<v-btn plain small @click="OnClickLogout"><v-icon small>mdi-logout</v-icon>logout</v-btn>
          </v-chip>
        </v-col>
      </v-row>
    </div>

    <v-row class="pa-0 ma-0" style="max-width: 100%; height: 94vh;">
      <v-col cols="2" class="pa-0 ma-0" style="height: 94vh;">
        <v-container class="pa-0 ma-0" style="height: 25vh;">

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-text-field v-model="search_text" hide-details="" dense outlined placeholder="search" ></v-text-field>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="primary secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black primary--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">CONNECT</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().run }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="warning secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black warning--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">WARNING</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().warning }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="error secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black error--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">ERROR</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().error }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="grey secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black grey--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">DISCONNECT</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().offline }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

        </v-container>
        <v-container class="pa-0 ma-0" style="height: 69vh; overflow-y: auto;">

          <v-row class="pa-1 ma-0" v-for="device in devices.filter(v => v.Get().device_serial.includes(search_text))" :key="device.Get().device_serial">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="pa-1 ma-0" elevation="10" :color="device_serial == device.Get().device_serial ? 'primary' : hover ? 'grey' : 'default'" v-on:click="OnClickDevice(device.Get().device_serial)" width="100%">
                  <v-card-subtitle class="pa-0 ma-0 d-flex">
                    <strong>{{ device.Get().device_index_alias }} ({{ device.Get().stack_list.length }})</strong>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="secondary" disabled>device</v-btn>
                  </v-card-subtitle>
                  <v-card-text class="pa-0 ma-0" v-for="stack_serial in device.Get().stack_list.sort()" :key="stack_serial+'device-sub'">

                    <v-container class="pa-0 ma-0 d-flex">
                      <v-row class="pa-0 ma-0" align="center" justify="end">
                        <v-col class="pa-0 ma-0 d-flex align-center justify-end">
                          {{ stack_serial }}
                          <v-icon 
                          v-if="(stack_manager.GetStackFromStackSerial(stack_serial)?.Get().summary_run_state)"
                          color="yellow"
                          small>
                            mdi-lightning-bolt
                          </v-icon>
                          <v-icon 
                          v-else
                          color="grey"
                          small>
                            mdi-lightning-bolt
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                    
                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-row>
          
          <v-row class="pa-1 ma-0" v-for="stack in nav_stack_infos.filter(v => v.stack_serial.includes(search_text))" :key="stack.stack_serial+'list'">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="pa-1 ma-0" elevation="10" :color="hover ? 'grey' : 'default'" v-on:click="OnClickStack(stack.stack_serial)" width="100%">
                  <v-card-subtitle class="pa-0 ma-0 d-flex">
                    <v-icon x-small v-if="stack.connect_state == 1 && stack.error_state == 0" color="success">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 1" color="warning">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 2" color="warning">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 3" color="error">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 4" color="error">mdi-square</v-icon>
                    <v-icon x-small v-else color="grey">mdi-square</v-icon>
                    &nbsp;
                    <strong>{{ stack.stack_serial }}</strong>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="secondary" disabled>
                      {{ stack.model_type == 0 ? 'IE-POWER 4' : stack.model_type == 1 ? 'IE-SOAR 2.4' : 'UNKNOWN' }}
                    </v-btn>
                    
                  </v-card-subtitle>
                </v-card>
              </template>
            </v-hover>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="10" class="pa-0 ma-0" style="max-width: 100% !important; height: 94vh;">
        <div class="pa-0 ma-0" style="max-width: 100% !important; height: 94vh; overflow: scroll;">
          
          <v-card v-if="selected_lifts.length != 0" class="pa-1 ma-0 transparent">
            <v-data-table
              :headers="lift_tb_headers"
              :items="selected_lifts"
              :items-per-page="-1"
              class="transparent"
              hide-default-footer
              >

              <template v-slot:[`item.stack_serial`]="{ item }">
                <v-btn small plain link color="primary" @click="OnClickStack(item.stack_serial)"><strong>{{ item.stack_serial }}</strong></v-btn>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn v-if="item.state_run == 0" icon large color="primary"><v-icon large>mdi-play-circle</v-icon></v-btn>
                <v-btn v-else icon large color="error"><v-icon large>mdi-stop-circle</v-icon></v-btn>
              </template>

              <template v-slot:[`item.run_sec`]="{ item }">
          
                  <div style="width: 100px; overflow: hidden;">
                  {{ 
                    Math.floor(item.run_sec / (60*60)).toString().padStart(2, '0') + ":"
                    + Math.floor(item.run_sec % (60*60) / 60).toString().padStart(2, '0') + ":"
                    + (item.run_sec % 60).toString().padStart(2, '0') 
                  }}
                  </div>
  
              </template>

              <template v-slot:[`item.output_power`]="{ item }">
                <v-progress-linear color="warning" :value="item.output_power / 4000 * 100" height="20">{{ item.output_power }} W</v-progress-linear>
              </template>

              <template v-slot:[`item.output_voltage`]="{ item }">
                <v-progress-linear :value="item.output_voltage" height="20">{{ item.output_voltage }} V</v-progress-linear>
              </template>

              <template v-slot:[`item.output_current`]="{ item }">
                <v-progress-linear :value="item.output_current" height="20">{{ item.output_current }} A</v-progress-linear>
              </template>

              <template v-slot:[`item.anode_pressure`]="{ item }">
                  <v-progress-linear :value="item.anode_pressure / 1e1" height="20">{{ item.anode_pressure }} mbar</v-progress-linear>
              </template>

              <template v-slot:[`item.error_state`]="{ item }">
                <!-- <v-icon v-if="item.error_state == 0" color="secondary">mdi-alert-rhombus</v-icon> -->
                <v-icon v-if="item.error_state == 1" color="error">mdi-alert-rhombus</v-icon>
              </template>

              <template v-slot:[`item.failure_state`]="{ item }">
                <v-icon v-if="item.failure_state == -1" color="secondary">mdi-head-snowflake</v-icon>
                <v-icon v-if="item.failure_state == 0" color="error">mdi-head-snowflake</v-icon>
                <v-icon v-if="item.failure_state == 1" color="success">mdi-head-snowflake</v-icon>
              </template>

              <template v-slot:[`item.temperature`]="{ item }">
              
                <div class="d-flex">
                  <div style="width: 40px;" class="grey--text">Out</div>
                  <div style="width: 35px;">{{ item.outlet_temperature }}</div>
                  <div style="width: 25px;" class="grey--text">℃</div>
                  <div style="width: 40px;" class="grey--text">In</div>
                  <div style="width: 35px;">{{ item.inlet_temperature }}</div>
                  <div style="width: 25px;" class="grey--text">℃</div>
                </div>
              
              </template>

            </v-data-table>
          </v-card>

          <v-card v-if="selected_soars.length != 0" class="pa-1 ma-0 transparent">
            <v-data-table
              :headers="soar_tb_headers"
              :items="selected_soars"
              :items-per-page="-1"
              class="transparent"
              hide-default-footer
              >

              <template v-slot:[`item.fcpm_state`]="{ item }">
                <v-btn-toggle multiple >
                  <v-btn small plain :color="item.soar_fcpm_state_run == 1 ? 'primary' : 'secondary'" v-model="item.soar_fcpm_state_run">run</v-btn>
                  <v-btn small plain :color="item.soar_fcpm_state_sleep == 1 ? 'primary' : 'secondary'" v-model="item.soar_fcpm_state_sleep">slp</v-btn>
                  <v-btn small plain :color="item.soar_fcpm_state_dst == 1 ? 'primary' : 'secondary'" v-model="item.soar_fcpm_state_dst">dst</v-btn>
                  <v-btn small plain :color="item.soar_fcpm_state_dc == 1 ? 'primary' : 'secondary'" v-model="item.soar_fcpm_state_dc">dc</v-btn>
                </v-btn-toggle>
              </template>

              <template v-slot:[`item.soar_tank_level`]="{ item }">
                <v-progress-linear :value="item.soar_tank_level / 511.5 * 100" height="20">{{ item.soar_tank_level }}</v-progress-linear>
              </template>

              <template v-slot:[`item.soar_output_power`]="{ item }">
                <v-progress-linear color="warning" :value="item.soar_output_power / 10230 * 100" height="20">{{ item.soar_output_power }}</v-progress-linear>
              </template>

              <template v-slot:[`item.soar_battery_power`]="{ item }">
                <v-progress-linear :value="item.soar_battery_power / 15470 * 100" height="20">{{ item.soar_battery_power }}</v-progress-linear>
              </template>

              <template v-slot:[`item.soar_battery_voltage`]="{ item }">
                <v-progress-linear :value="item.soar_battery_voltage / 1023 * 100" height="20">{{ item.soar_battery_voltage }}</v-progress-linear>
              </template>

              <template v-slot:[`item.soar_spm_input_power`]="{ item }">
                <v-progress-linear :value="item.soar_spm_input_power / 5115 * 100" height="20">{{ item.soar_spm_input_power }}</v-progress-linear>
              </template>

              <template v-slot:[`item.stack_serial`]="{ item }">
                <v-btn small plain link color="primary" @click="OnClickStack(item.stack_serial)"><strong>{{ item.stack_serial }}</strong></v-btn>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn v-if="item.soar_fcpm_state_run == 0" icon large color="primary"><v-icon large>mdi-play-circle</v-icon></v-btn>
                <v-btn v-else icon large color="error"><v-icon large>mdi-stop-circle</v-icon></v-btn>
              </template>

            </v-data-table>
          </v-card>

          <v-card class="pa-1 ma-0 transparent">
            <v-row class="pa-0 ma-0" justify="end"><v-chip label>Total Output: {{ total_output_power }}W</v-chip></v-row>
          </v-card>

          <v-row align="end" justify="end">
            <v-col cols="11">
              <apexchart ref="chart" :height="300" id="chart" :options="chartOptions" :series="series"></apexchart>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 transparent" v-for="stack_serial in device_manager.GetDeviceFromDeviceSerial(device_serial)?.Get().stack_list" :key="stack_serial + 'fault'">
            <v-col cols="12" class="pa-0 ma-0 transparent">
              <v-card class="pa-0 ma-0 transparent">
                <v-card-text class="pa-1 ma-0" v-for="(fault, index) in stack_manager.GetStackFaultList(stack_serial)" :key="stack_serial + 'fault' + index">
                    <v-container class="pa-0 ma-0">
                      <v-row class="pa-0 ma-0" align="center" justify="space-between">
                        <v-col class="pa-0 ma-0">[{{ fault.time }}] <strong>{{ stack_serial }} {{ fault.tag }}</strong></v-col>
                        <v-col class="pa-0 ma-0">{{ fault.des }}</v-col>
                      </v-row>
                    </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script lang="ts">
import router from '@/router';
import DEVICE from '@/services/device/Device';
import DeviceManager from '@/services/device/DeviceManager';
import STACK from '@/services/stack/Stack';
import { StackFaults, STACK_FAULT } from '@/services/stack/StackFaults';
import StackManager, { NAV_STACK_INFO } from '@/services/stack/StackManager';
import { RequestStackData, StopReceiveStackData } from '@/services/websocket/WebsocketSender';
import store from '@/store';
import Vue, { computed } from 'vue'
import VueApexCharts from 'vue-apexcharts'

interface OUTPUT_POWER_DATA {
  name: string,
  data: number[][],
}

export default Vue.extend({
  name: 'DeviceDetail',

  props: {
    device_serial: {
      type: String,
      required: true,
      default: '',
    },
  },

  components: {
    apexchart: VueApexCharts
    // VueApexCharts
  },

  data() {
    return {
      search_text: '' as string,
      show_only: 0,
      device_manager: DeviceManager.getInstance() as DeviceManager,
      stack_manager: StackManager.getInstance() as StackManager,
      selected_stacks: StackManager.getInstance().GetStackFromDeviceSerial(this.device_serial),
      selected_lifts: [] as STACK[],
      selected_soars: [] as STACK[],
      stacks: [] as STACK[],
      devices: [] as DEVICE[],
      nav_stack_infos: [] as NAV_STACK_INFO[],

      chart_update_job: 0,
      chart_output_power_data: new Map() as Map<string,OUTPUT_POWER_DATA>,

      total_output_power: 0,

      lift_tb_headers: [
        // {
        //   text: 'No',
        //   value: 'idx',
        // },
        {
          text: 'Serial',
          value: 'stack_serial',
        },
        {
          text: 'Control',
          value: 'actions',
        },
        // {
        //   text: 'Ver',
        //   value: 'sw_version'
        // },
        // {
        //   text: 'Use',
        //   value: 'use_type'
        // },
        // {
        //   text: 'Model',
        //   value: 'model_type'
        // },
        // {
        //   text: 'Interface',
        //   value: 'connection_type'
        // },
        // {
        //   text: 'Linked',
        //   value: 'edge_serial'
        // },
        
        /**
         *     private sw_version = '';
              private run_sec = 0;
              private total_run_sec = 0;
              private output_power = 0;
              private output_voltage = 0;
              private output_current = 0;
              private dcdc_voltage_set_point = 0;
              private dcdc_current_limit = 0;
              private louver_position = 0;
              private dcdc_disabled = 0;
              private on_load = 0;
              private dcdc_enable = 0;
        */

        {
          text: 'Output Power',
          value: 'output_power',
        },
        {
          text: 'Output Voltage',
          value: 'output_voltage',
        },
        {
          text: 'Output Current',
          value: 'output_current',
        },
        {
          text: 'Anode Pressure',
          value: 'anode_pressure',
        },
        // {
        //   text: 'Output',
        //   value: 'output',
        //   width: lift_tb_cell_width,
        // },

        // {
        //   text: 'Outlet Temperature',
        //   value: 'outlet_temperature',
        //   width: lift_tb_cell_width,
        // },

        // {
        //   text: 'Inlet Temperature',
        //   value: 'inlet_temperature',
        //   width: lift_tb_cell_width,
        // },
        {
          text: 'Temperature',
          value: 'temperature',
        },


        // {
        //   text: 'Fan pulse',
        //   value: 'fan_pulse'
        // },

        // {
        //   text: 'Serated',
        //   value: 'serated'
        // },

        // {
        //   text: 'Power from Stack',
        //   value: 'power_from_stack'
        // },
        // {
        //   text: 'Power from External',
        //   value: 'power_from_external'
        // },

        // {
        //   text: 'Fan SP Duty',
        //   value: 'fan_sp_duty'
        // },


        // {
        //   text: 'SV01',
        //   value: 'sv01'
        // },
        // {
        //   text: 'SV02',
        //   value: 'sv02'
        // },
        // {
        //   text: 'SV04',
        //   value: 'sv04'
        // },

        // {
        //   text: 'Run',
        //   value: 'state_run'
        // },
        // {
        //   text: 'Standby',
        //   value: 'state_standby'
        // },
        // {
        //   text: 'Inactive',
        //   value: 'state_inactive'
        // },
        // {
        //   text: 'Fault',
        //   value: 'state_fault'
        // },
        {
          text: 'Error',
          value: 'error_state',
        },
        {
          text: 'AI',
          value: 'failure_state',
        },
        // {
        //   text: 'Louver',
        //   value: 'louver_open'
        // },
        {
          text: 'Running',
          value: 'run_sec',
        },

        
      ],

      soar_tb_headers: [
        {
          text: 'Serial',
          value: 'stack_serial'
        },
        {
          text: 'Control',
          value: 'actions'
        },
        {
          text: 'Output Power',
          value: 'soar_output_power'
        },
        // {
        //   text: 'Cyclic count',
        //   value: 'soar_cyclic_counter'
        // },
        // {
        //   text: 'Run',
        //   value: 'soar_fcpm_state_run'
        // },
        // {
        //   text: 'Sleep',
        //   value: 'soar_fcpm_state_sleep'
        // },
        // {
        //   text: 'DST',
        //   value: 'soar_fcpm_state_dst'
        // },
        // {
        //   text: 'DC',
        //   value: 'soar_fcpm_state_dc'
        // },
        {
          text: 'FCPM State',
          value: 'fcpm_state',
          sortable: false,
        },
        {
          text: 'Tank Level',
          value: 'soar_tank_level'
        },
        {
          text: 'SPM Input Power',
          value: 'soar_spm_input_power'
        },
        {
          text: 'Battery Voltage',
          value: 'soar_battery_voltage'
        },
        {
          text: 'Battery Power',
          value: 'soar_battery_power'
        },

      ],

      series: [
        {
          data: [],
        }
      ],
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
              customIcons: []
            },
          },
          foreColor: '#fff',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
        },
        tooltip: {
          enabled: false,
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        // dataLabels: {
        //   enabled: true,
        // },
        // stroke: {
        //   curve: 'smooth'
        // },
        xaxis: {
          type: 'datetime',
          range: 10000,
          labels: {
            show: false,
            // formatter: function (value:number) {
            //   console.log(new Date().getTimezoneOffset())
            //   value = value - 2*(new Date().getTimezoneOffset() * 60 * 1000);
            //   return new Date(value);
            // }
          },
        },
        yaxis: {
          // max: 4000,
          // min: 0,
          title: {
            text: 'Output Power'
          },
        },
        legend: {
          show: true
        },
      },
    };
  },

  beforeUpdate() {
      // console.log('update')
  },

  mounted() {
      console.log(this.$route)
      const device:DEVICE|undefined = DeviceManager.getInstance().GetDeviceFromDeviceSerial(this.device_serial);
      if(device != undefined) {
        RequestStackData(Array.from(device.Get().stack_list.values()));

        this.selected_lifts = this.FilteringLIFT(this.selected_stacks);
        this.selected_soars = this.FilteringSOAR(this.selected_stacks);

        const stack_serial_list:string[] = Array.from(device.Get().stack_list.values()).sort();
        this.chart_update_job = setInterval(() => {
          let total_output_power = 0;
          stack_serial_list.forEach((stack_serial:string) => {
            const stack:STACK|undefined = this.stack_manager.GetStackFromStackSerial(stack_serial);
            if(stack != undefined) {
              const output:number = stack.Get().model_type == 0 ? stack.Get().output_power : stack.Get().soar_output_power;
              total_output_power += output;
              if(this.chart_output_power_data.has(stack_serial)) {
                const data:OUTPUT_POWER_DATA|undefined = this.chart_output_power_data.get(stack_serial);
                if(data != undefined) {
                  data.data.push([Date.now(), output])
                }

                if(data != undefined) {
                  if(data.data.length > 60) {
                    data.data.splice(0, 50);
                  }
                }
              }
              else {
                this.chart_output_power_data.set(stack_serial, {
                  name: stack_serial,
                  data: [[Date.now(), output]],
                })
              }
            }
          });

          this.total_output_power = total_output_power;

          const chart:any = this.$refs.chart;
          if(chart) chart.updateSeries(Array.from(this.chart_output_power_data.values()), true)
        }, 1000)
      }
  },

  setup() {
    const store_auth_id = computed<string>(() => store.getters.GetAuthId);
    const store_stacks = computed<STACK[]>(() => store.getters.GetStacks);
    const store_devices = computed<DEVICE[]>(() => store.getters.GetDevices);
    const store_nav_stack_infos = computed<NAV_STACK_INFO[]>(() => store.getters.GetStackNavList);
    return { store_auth_id, store_stacks, store_devices, store_nav_stack_infos };
  },

  watch: {
    store_stacks: {
      handler(val, oldVal) {
        this.stacks = val;
      },
      // deep: true,
    },
    store_devices: {
      handler(val, oldVal) {
        this.devices = val;
      },
      // deep: true,
    },
    store_nav_stack_infos: {
      handler(val, oldVal) {
          this.nav_stack_infos = val;
      },
    }
  },

  methods: {
    OnClickLogout() {
      window.location.reload();
    },
    OnClickGoHome() {
      StopReceiveStackData();
      router.push({name: 'home'});
    },
    OnClickGoReport() {
      StopReceiveStackData();
      router.push({name: 'report'});
    },
    OnClickStack(serial:string) {
      if(this.$route.path !== `/stack/${serial}`) {
        router.push({name: 'stack', params: {stack_serial: serial}});
        RequestStackData([serial]);
        // this.selected_stack = StackManager.getInstance().GetStackFromStackSerial(serial);
      }
    },
    OnClickDevice(device_serial:string) {
      if(this.$route.path !== `/device/${device_serial}`) {
        router.push({name: 'device', params: {device_serial: device_serial}});
      }
      const device:DEVICE|undefined = DeviceManager.getInstance().GetDeviceFromDeviceSerial(device_serial);
      if(device != undefined) {
        RequestStackData(Array.from(device.Get().stack_list.values()));
        this.selected_stacks = StackManager.getInstance().GetStackFromDeviceSerial(device_serial);
        this.selected_lifts = this.FilteringLIFT(this.selected_stacks);
        this.selected_soars = this.FilteringSOAR(this.selected_stacks);

        const stack_serial_list:string[] = Array.from(device.Get().stack_list.values()).sort();
        clearInterval(this.chart_update_job);
        this.chart_output_power_data.clear();
        this.chart_update_job = setInterval(() => {
          stack_serial_list.forEach((stack_serial:string) => {
            const stack:STACK|undefined = this.stack_manager.GetStackFromStackSerial(stack_serial);
            if(stack != undefined) {
              const output:number = stack.Get().model_type == 0 ? stack.Get().output_power : stack.Get().soar_output_power;
              if(this.chart_output_power_data.has(stack_serial)) {
                const data:OUTPUT_POWER_DATA|undefined = this.chart_output_power_data.get(stack_serial);
                if(data != undefined) {
                  data.data.push([Date.now(), output])
                }
              }
              else {
                this.chart_output_power_data.set(stack_serial, {
                  name: stack_serial,
                  data: [[Date.now(), output]],
                })
              }
            }
          });

          const chart:any = this.$refs.chart;
          if(chart) chart.updateSeries(Array.from(this.chart_output_power_data.values()), true)
        }, 1000)
      }
    },
    SelectedDeviceStackType(selected_stacks:STACK[]) {
      let ret = 0; //  0:LIFT 1:SOAR
      selected_stacks.forEach((selected_stack:STACK) => {
        ret = selected_stack.Get().model_type;
      });
      return ret;
    },
    FilteringLIFT(selected_stacks:STACK[]) {
      const tmp:STACK[] = [];
      selected_stacks.forEach((selected_stack:STACK) => {
        if(selected_stack.Get().model_type == 0) {
          tmp.push(selected_stack);
        }
      });
      return tmp;
    },
    FilteringSOAR(selected_stacks:STACK[]) {
      const tmp:STACK[] = [];
      selected_stacks.forEach((selected_stack:STACK) => {
        if(selected_stack.Get().model_type == 1) {
          tmp.push(selected_stack);
        }
      });
      return tmp;
    },
    DateFormat(dt:Date) {
      const yyyy = dt.getFullYear().toString().padStart(4, '0');
      const MM = (dt.getMonth() + 1).toString().padStart(2, '0');
      const dd = dt.getDate().toString().padStart(2, '0');
      const hh = dt.getHours().toString().padStart(2, '0');
      const mm = dt.getMinutes().toString().padStart(2, '0');
      const ss = dt.getSeconds().toString().padStart(2, '0');
      return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
    },
  }
})
</script>

<style scoped>
.bg {
  background-image: linear-gradient(
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),url(@/assets/h2img.png);
  background-position: right bottom;
}
</style>

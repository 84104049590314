import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// import VueApexCharts from 'vue-apexcharts'
// Vue.use(VueApexCharts);
// Vue.component('ApexChart', VueApexCharts);

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://hsm.hogreenair.com/' : '/';
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

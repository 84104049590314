import { STRUCT_LIQUID_STATE } from "../websocket/WebsocketInterface";

export default class LIQUID {
    private liquid_serial = '';
    private liquid_state:STRUCT_LIQUID_STATE;
    constructor(liquid_serial:string, liquid_state:STRUCT_LIQUID_STATE) {
        this.liquid_serial = liquid_serial;
        this.liquid_state = liquid_state;
    }

    Set(liquid_state:STRUCT_LIQUID_STATE) {
        this.liquid_state = liquid_state;
    }

    Get() {
        return {
            liquid_serial: this.liquid_serial,
            liquid_state: this.liquid_state,
        }
    }
}
<template>
  <div class="bg" style="width: 100vw; height: 100vh;">
    <v-container class="pa-0 ma-0" style="max-width: 100%; height: 100%;">

      <v-row class="pa-0 ma-0" align="center" justify="center" style="height: 100%;">

        <v-container class="pa-0 ma-0">
          <v-row class="pa-0 ma-0" align="center" justify="center">
            <h1>HOGREENAIR STACK MONITORING</h1>
          </v-row>

          <v-row class="pa-0 ma-0" align="center" justify="center">
            <v-col cols="3" class="pa-0 ma-0"><v-text-field v-model="id" label="ID" clearable @keypress.enter="Auth"></v-text-field></v-col>
          </v-row>

          <v-row class="pa-0 ma-0" align="center" justify="center">
            <v-col cols="3" class="pa-0 ma-0"><v-text-field v-model="pw" type="password" label="Password" clearable @keypress.enter="Auth"></v-text-field></v-col>
          </v-row>

          <v-row class="pa-0 ma-0" align="center" justify="center">
            <v-col cols="3"><v-btn v-on:click="Auth" width="100%">sign in</v-btn></v-col>
          </v-row>
        </v-container>

      </v-row>
      
    </v-container>
  </div>
</template>

<script lang="ts">
  import { Authentication } from '@/services/websocket/WebsocketSender';
import Vue from 'vue'

  export default Vue.extend({
    name: 'LoginComponent',

    data: () => ({
      id: 'hw',
      pw: '1234567',
    }),

    methods: {
      Auth() {
        Authentication(this.id, this.pw);
      }
    }
  })
</script>

<style scoped>
.bg {
  background-image: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),url(@/assets/h2img.png);
  background-position: right bottom;
}
</style>
<template>
  <div class="bg" style="width: 100vw; height: 100vh;">
    <div style="width: 100vw; height: 6vh;">
      <v-row class="pa-0 ma-0" align="center" justify="center" style="height: 6vh;">
        <v-col class="pa-0 ma-0">
          <v-img src="@/assets/hogreen-logo.png" :width="130" v-on:click="OnClickGoHome" style="cursor: pointer;"></v-img>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: center;">
          <h1><strong>{{ stack_serial }}</strong></h1>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: right;">
          <v-btn small @click="OnClickGoHome"><v-icon small>mdi-home</v-icon>home</v-btn>
          &nbsp;
          <v-btn small @click="OnClickGoReport"><v-icon small>mdi-chart-line</v-icon>report</v-btn>
          &nbsp;
          <v-chip small label>
            <v-icon small>mdi-account</v-icon>{{ store_auth_id }}<v-btn plain small @click="OnClickLogout"><v-icon small>mdi-logout</v-icon>logout</v-btn>
          </v-chip>
        </v-col>
      </v-row>
    </div>

    <v-row class="pa-0 ma-0" style="width: 100vw; height: 94vh;">
      <v-col cols="2" class="pa-0 ma-0" style="height: 94vh;">
        <v-container class="pa-0 ma-0" style="height: 25vh;">

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-text-field v-model="search_text" hide-details="" dense outlined placeholder="search" ></v-text-field>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="primary secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black primary--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">CONNECT</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().run }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="warning secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black warning--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">WARNING</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().warning }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="error secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black error--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">ERROR</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().error }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

          <v-row class="pa-1 ma-0" style="height: 5vh;">
            <v-sheet width="100%" height="100%" rounded outlined color="grey secondary--text" class="pa-0 ma-0">
              <v-card class="pa-0 ma-0" color="black grey--text" height="100%">
                <v-container class="pa-0 ma-0" style="height: 100%;">
                  <v-row class="pa-0 ma-0" style="height: 100%;">
                    <v-col cols="9" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="start" justify="start" style="height: 100%;">
                          <h4 style="letter-spacing: 0ch;">DISCONNECT</h4>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0" style="height: 100%;">
                      <v-container class="pa-0 ma-0" style="height: 100%;">
                        <v-row class="pa-0 ma-0" align="end" justify="end" style="height: 100%;">
                          <h3>{{ stack_manager.GetStackStateCount().offline }}</h3>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-sheet>
          </v-row>

        </v-container>

        <v-container class="pa-0 ma-0" style="height: 69vh; overflow-y: auto;">
          <v-row class="pa-1 ma-0" v-for="device in devices.filter(v => v.Get().device_serial.includes(search_text))" :key="device.Get().device_serial">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="pa-1 ma-0" elevation="10" :color="hover ? 'grey' : 'default'" v-on:click="OnClickDevice(device.Get().device_serial)" width="100%">
                  <v-card-subtitle class="pa-0 ma-0 d-flex">
                    <strong>{{ device.Get().device_index_alias }} ({{ device.Get().stack_list.length }})</strong>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="secondary" disabled>device</v-btn>
                  </v-card-subtitle>
                  <v-card-text class="pa-0 ma-0" v-for="stack_serial in device.Get().stack_list.sort()" :key="stack_serial">

                    <v-container class="pa-0 ma-0 d-flex">
                      <v-row class="pa-0 ma-0" align="center" justify="end">
                        <v-col class="pa-0 ma-0 d-flex align-center justify-end">
                          {{ stack_serial }}
                          <v-icon 
                          v-if="(stack_manager.GetStackFromStackSerial(stack_serial)?.Get().summary_run_state)"
                          color="yellow"
                          small>
                            mdi-lightning-bolt
                          </v-icon>
                          <v-icon 
                          v-else
                          color="grey"
                          small>
                            mdi-lightning-bolt
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>

                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-row>

          <v-row class="pa-1 ma-0" v-for="stack in nav_stack_infos.filter(v => v.stack_serial.includes(search_text))" :key="stack.stack_serial">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="pa-1 ma-0" elevation="10" :color="selected_stack?.Get().stack_serial == stack.stack_serial ? 'primary' : hover ? 'grey' : 'default'" v-on:click="OnClickStack(stack.stack_serial)" width="100%">
                  <v-card-subtitle class="pa-0 ma-0 d-flex">
                    <v-icon x-small v-if="stack.connect_state == 1 && stack.error_state == 0" color="success">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 1" color="warning">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 2" color="warning">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 3" color="error">mdi-square</v-icon>
                    <v-icon x-small v-else-if="stack.connect_state == 1 && stack.error_state == 4" color="error">mdi-square</v-icon>
                    <v-icon x-small v-else color="grey">mdi-square</v-icon>
                    &nbsp;
                    <strong>{{ stack.stack_serial }}</strong>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="secondary" disabled>
                      {{ stack.model_type == 0 ? 'IE-POWER 4' : stack.model_type == 1 ? 'IE-SOAR 2.4' : 'UNKNOWN' }}
                    </v-btn>
                    
                  </v-card-subtitle>
                </v-card>
              </template>
            </v-hover>
          </v-row>
        </v-container>

      </v-col>

      <v-col cols="10" class="pa-0 ma-0" style="max-width: 100% !important;">
        <v-container v-if="selected_stack?.Get().model_type == 0" class="pa-0 ma-0" style="max-width: 100% !important; height: 100%;">
          <v-row class="pa-0 ma-0">
            <v-col cols="3" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Outlet Temperature</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().outlet_temperature }} ℃</h2></v-row>
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Inlet Temperature</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().inlet_temperature }} ℃</h2></v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>
            
            <v-col cols="3" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Fan SP Duty</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().fan_sp_duty }} %</h2></v-row>
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Louver Position</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().louver_position }} %</h2></v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

            <v-col cols="3" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Output Power</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().output_power }} W</h2></v-row>
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Output Voltage</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().output_voltage }} V</h2></v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

            <v-col cols="3" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Output Current</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().output_current }} A</h2></v-row>
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Anode Pressure</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().anode_pressure }} mbar</h2></v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

          </v-row>

          <v-row class="pa-0 ma-0">

            <v-col cols="6" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">
                      State
                    </v-row>
                    <v-row class="pa-1 ma-0" align="center" justify="center">
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().state_inactive ? 'primary--text' : 'grey--text']"><h2>INACTIVE</h2></v-col>
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().state_standby ? 'primary--text' : 'grey--text']"><h2>STANDBY</h2></v-col>
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().state_run ? 'primary--text' : 'grey--text']"><h2>RUN</h2></v-col>
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().state_fault ? 'error--text' : 'grey--text']"><h2>FAULT</h2></v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

            <v-col cols="2" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Running Time</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center">
                      <h2>
                        {{ selected_stack 
                        ? Math.floor(selected_stack.Get().run_sec / (60*60)).toString().padStart(2, '0') + ":"
                        + Math.floor(selected_stack.Get().run_sec % (60*60) / 60).toString().padStart(2, '0') + ":"
                        + (selected_stack.Get().run_sec % 60).toString().padStart(2, '0')
                        : '00:00:00' }}
                      </h2>
                    </v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>
            <v-col cols="2" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Total Run Energy</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center">
                      <h2>
                        {{ selected_stack?.Get().total_run_energy }} J
                      </h2>
                    </v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>
            <v-col cols="2" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">AI Failure Prediction</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center">
                      <h2>
                        <v-icon title="unknown" v-if="selected_stack?.Get().failure_state == -1" class="grey--text">mdi-circle-slice-8</v-icon>
                        <v-icon title="unknown" v-else class="grey--text" style="opacity: .2;">mdi-circle</v-icon>

                        <v-icon title="failure expected" v-if="selected_stack?.Get().failure_state == 0" class="error--text">mdi-circle-slice-8</v-icon>
                        <v-icon title="failure expected" v-else class="error--text" style="opacity: .2;">mdi-circle</v-icon>

                        <v-icon title="normal" v-if="selected_stack?.Get().failure_state == 1" class="success--text">mdi-circle-slice-8</v-icon>
                        <v-icon title="normal" v-else class="success--text" style="opacity: .2;">mdi-circle</v-icon>
                      </h2>
                    </v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">

            <v-col cols="4" class="pa-2 ma-0">
              <v-container class="pa-5 ma-0">
                <v-row class="pa-1 ma-0" align="center" justify="center">
                  <v-progress-circular rotate="270" :size="250" :width="20" color="primary" :value="selected_stack?.Get().output_power / 40">
                    <v-container>
                      <v-row class="pa-0 ma-0 white--text" align="center" justify="center">
                        <h1>{{ selected_stack ? selected_stack.Get().output_power : '' }}</h1>
                      </v-row>
                      <v-row class="pa-0 ma-0 grey--text" align="center" justify="center"><h2><v-icon x-large color="grey">mdi-lightning-bolt</v-icon></h2></v-row>
                    </v-container>
                  </v-progress-circular>
                </v-row>
              </v-container>
            </v-col>

            <v-col cols="4" class="pa-2 ma-0" style="">
              <v-container class="pa-0 ma-0 d-flex align-center justify-center" style="height: 100%;">
                <v-row class="pa-0 ma-0" align="center" justify="center">
                    <v-btn v-if="selected_stack?.Get().run_enable" x-large class="pa-5 ma-5 primary"><v-icon x-large>mdi-play-circle</v-icon><h2>start</h2></v-btn>
                    <v-btn v-else x-large class="pa-5 ma-5 secondary" disabled><v-icon x-large>mdi-play-circle</v-icon><h2>start</h2></v-btn>

                    <v-btn v-if="!selected_stack?.Get().run_enable" x-large class="pa-5 ma-5 error"><v-icon x-large>mdi-stop-circle</v-icon><h2>stop</h2></v-btn>
                    <v-btn v-else x-large class="pa-5 ma-5 error" disabled><v-icon x-large>mdi-stop-circle</v-icon><h2>stop</h2></v-btn>

                  <v-container class="pa-0 ma-0" style="height: 35vh; overflow: scroll;">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">
                      Fault
                    </v-row>
                    <v-row class="pa-1 ma-0" align="center" justify="center" v-for="(fault, index) in selected_stack.GetFaults()" :key="selected_stack.Get().stack_serial + 'fault' + index">
                      <v-col class="pa-0 ma-0 grey--text">[{{ fault.time }}] {{ fault.tag }}</v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-col>

            <v-col cols="4" class="pa-2 ma-0">
              <v-container class="pa-5 ma-0">
                <v-row class="pa-1 ma-0" align="center" justify="center">
                  <v-progress-circular rotate="270" :size="250" :width="20" color="primary" :value="selected_stack?.Get().anode_pressure / 1e1">
                    <v-container>
                      <v-row class="pa-0 ma-0 white--text" align="center" justify="center">
                        <h1>{{ selected_stack ? selected_stack.Get().anode_pressure : '' }}</h1>
                      </v-row>
                      <v-row class="pa-0 ma-0 grey--text" align="center" justify="center"><h2>Pressure / mbar</h2></v-row>
                    </v-container>
                  </v-progress-circular>
                </v-row>
              </v-container>
            </v-col>

          </v-row>

        </v-container>

        <v-container v-if="selected_stack?.Get().model_type == 1" class="pa-0 ma-0" style="max-width: 100% !important; height: 100%;">
          <v-row class="pa-0 ma-0">
            <v-col cols="4" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Battery Voltage</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().soar_battery_voltage }} V</h2></v-row>
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Battery Power</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().soar_battery_power }}</h2></v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

            <v-col cols="4" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Output Power</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().soar_output_power }}</h2></v-row>
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">SPM Input Power</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().soar_spm_input_power }}</h2></v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

            <v-col cols="4" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Tank Level</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().soar_tank_level }}</h2></v-row>
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">Cyclic Counter</v-row>
                    <v-row class="pa-1 ma-0 primary--text" align="center" justify="center"><h2>{{ selected_stack?.Get().soar_cyclic_counter }}</h2></v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

          </v-row>

          <v-row class="pa-0 ma-0">

            <v-col cols="6" class="pa-2 ma-0">
              <v-sheet outlined color="primary" elevation="20" rounded>
                <v-card class="pa-5 ma-0" elevation="20">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">
                      FCPM State
                    </v-row>
                    <v-row class="pa-1 ma-0" align="center" justify="center">
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().soar_fcpm_state_run ? 'primary--text' : 'grey--text']"><h2>RUN</h2></v-col>
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().soar_fcpm_state_sleep ? 'primary--text' : 'grey--text']"><h2>SLEEP</h2></v-col>
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().soar_fcpm_state_dst ? 'primary--text' : 'grey--text']"><h2>DST</h2></v-col>
                      <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center" :class="[selected_stack?.Get().soar_fcpm_state_dc ? 'primary--text' : 'grey--text']"><h2>DC</h2></v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-sheet>
            </v-col>

          </v-row>


          <v-row class="pa-0 ma-0">

            <v-col cols="4" class="pa-2 ma-0">
              <v-container class="pa-5 ma-0">
                <v-row class="pa-1 ma-0" align="center" justify="center">
                  <v-progress-circular rotate="270" :size="250" :width="20" color="primary" :value="selected_stack ? selected_stack.Get().soar_spm_input_power / 2047 * 100 : 0">
                    <v-container>
                      <v-row class="pa-0 ma-0 white--text" align="center" justify="center">
                        <h1>{{ selected_stack ? selected_stack.Get().soar_spm_input_power : '' }}</h1>
                      </v-row>
                      <v-row class="pa-0 ma-0 grey--text" align="center" justify="center"><h2>SPM Input Power</h2></v-row>
                    </v-container>
                  </v-progress-circular>
                </v-row>
              </v-container>
            </v-col>

            <v-col cols="4" class="pa-2 ma-0" style="">
              <v-container class="pa-0 ma-0 d-flex align-center justify-center" style="height: 100%;">
                <v-row class="pa-0 ma-0" align="center" justify="center">
                  <!-- <v-card class="pa-2 ma-0"> -->
                    <v-btn v-if="selected_stack?.Get().run_enable" x-large class="pa-5 ma-5 primary"><v-icon x-large>mdi-play-circle</v-icon><h2>start</h2></v-btn>
                    <v-btn v-else x-large class="pa-5 ma-5 secondary" disabled><v-icon x-large>mdi-play-circle</v-icon><h2>start</h2></v-btn>

                    <v-btn v-if="!selected_stack?.Get().run_enable" x-large class="pa-5 ma-5 error"><v-icon x-large>mdi-stop-circle</v-icon><h2>stop</h2></v-btn>
                    <v-btn v-else x-large class="pa-5 ma-5 error" disabled><v-icon x-large>mdi-stop-circle</v-icon><h2>stop</h2></v-btn>
                  <!-- </v-card> -->

                  <v-container class="pa-0 ma-0" style="height: 35vh; overflow: scroll;">
                    <v-row class="pa-1 ma-0 grey--text" align="center" justify="center">
                      Fault
                    </v-row>
                    <v-row class="pa-1 ma-0" align="center" justify="center" v-for="(fault, index) in selected_stack.GetFaults()" :key="selected_stack.Get().stack_serial + 'fault' + index">
                      <v-col class="pa-0 ma-0 grey--text">[{{ fault.time }}] {{ fault.tag }}</v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-col>

            <v-col cols="4" class="pa-2 ma-0">
              <v-container class="pa-5 ma-0">
                <v-row class="pa-1 ma-0" align="center" justify="center">
                  <v-progress-circular rotate="270" :size="250" :width="20" color="primary" :value="selected_stack ? selected_stack.Get().soar_output_power / 10231 * 100 : 0">
                    <v-container>
                      <v-row class="pa-0 ma-0 white--text" align="center" justify="center">
                        <h1>{{ selected_stack ? selected_stack.Get().soar_output_power : '' }}</h1>
                      </v-row>
                      <v-row class="pa-0 ma-0 grey--text" align="center" justify="center"><h2>Output Power</h2></v-row>
                    </v-container>
                  </v-progress-circular>
                </v-row>
              </v-container>
            </v-col>

          </v-row>

        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import DEVICE from '@/services/device/Device';
import DeviceManager from '@/services/device/DeviceManager';
import STACK from '@/services/stack/Stack';
import StackManager, { NAV_STACK_INFO } from '@/services/stack/StackManager';
import { RequestStackData, StopReceiveStackData } from '@/services/websocket/WebsocketSender';
import store from '@/store';
import Vue, { computed } from 'vue'

export default Vue.extend({
  name: 'StackDetail',

  props: {
    stack_serial: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      search_text: '' as string,
      show_only: 0,
      stack_manager: StackManager.getInstance() as StackManager,
      selected_stack: StackManager.getInstance().GetStackFromStackSerial(this.stack_serial),
      stacks: [] as STACK[],
      devices: [] as DEVICE[],
      nav_stack_infos: [] as NAV_STACK_INFO[],
    };
  },

  mounted() {
      console.log(this.$route)
      console.log(this.stack_serial);
  },

  setup() {
    const store_auth_id = computed<string>(() => store.getters.GetAuthId);
    const store_stacks = computed<STACK[]>(() => store.getters.GetStacks);
    const store_devices = computed<DEVICE[]>(() => store.getters.GetDevices);
    const store_nav_stack_infos = computed<NAV_STACK_INFO[]>(() => store.getters.GetStackNavList);
    return { store_auth_id, store_stacks, store_devices, store_nav_stack_infos };
  },

  watch: {
    store_stacks: {
      handler(val, oldVal) {
        this.stacks = val;
      },
    },
    store_devices: {
      handler(val, oldVal) {
        this.devices = val;
      },
    },
    store_nav_stack_infos: {
      handler(val, oldVal) {
        this.nav_stack_infos = val;
      },
    },
  },
  
  beforeUpdate() {
      // console.log('update')
  },

  methods: {
    OnClickGoHome() {
      StopReceiveStackData();
      router.push({name: 'home'});
    },
    OnClickGoReport() {
      StopReceiveStackData();
      router.push({name: 'report'});
    },
    OnClickStack(serial:string) {
      if(this.$route.path !== `/stack/${serial}`) {
        router.push({name: 'stack', params: {stack_serial: serial}});
        RequestStackData([serial]);
        this.selected_stack = StackManager.getInstance().GetStackFromStackSerial(serial);
      }
    },
    OnClickDevice(device_serial:string) {
      if(this.$route.path !== `/device/${device_serial}`) {
        router.push({name: 'device', params: {device_serial: device_serial}});
      }
      const device:DEVICE|undefined = DeviceManager.getInstance().GetDeviceFromDeviceSerial(device_serial);
      if(device != undefined) {
        RequestStackData(Array.from(device.Get().stack_list.values()));
      }
    },
    OnClickLogout() {
      window.location.reload();
    },
  }
})
</script>

<style scoped>
.bg {
  background-image: linear-gradient(
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),url(@/assets/h2img.png);
  background-position: right bottom;
}
</style>

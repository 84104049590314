import store from "@/store";
import LIQUID from "./Liquid";
import { STRUCT_LIQUID_STATE } from "../websocket/WebsocketInterface";

class LiquidManager {
    private static instance: LiquidManager;

    private _liquid_list:Map<string, LIQUID> = new Map();

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    constructor() {
        // this.dummy_add();
        this.worker();
    }

    private worker() {
        setTimeout(() => {
            store.commit('SetLiquids', Array.from(this._liquid_list.values()));
            this.worker();
        }, 500)
    }

    private dummy_add() {
        const liquid_state:STRUCT_LIQUID_STATE = {
            _year: 2024,
            _month: 1, 
            _day: 16,
            _hour: 14,
            _min: 14,
            _sec: 14,
            _coolant_in_temp: 1,
            _coolant_out_temp: 1,
            _cooler_status: 1,
            _gh2_inlet: 1,
            _gh2_vent: 1,
            _kg_per_day: 1,
            _lhr: 1,
            _lhrkw: 1,
            _mfc_gs: 1,
            _name: 'L-2',
            _outer_tank_vacuum: 1,
            _positioner_valve: 1,
            _temperature_1: 1,
            _temperature_2: 1,
            _temperature_3: 1,
            _temperature_4: 1,
            _temperature_5: 1,
            _temperature_6: 1,
            _temperature_7: 1,
            _temperature_8: 1,
            _temperature_10: 1,
            _vacuum: 1,
        }
        const liquid:LIQUID = new LIQUID(
            'L-2',
            liquid_state
        )

        this._liquid_list.set(liquid_state._name, liquid);
    }

    GetLiquidFromSerial(serial:string):LIQUID|undefined {
        return this._liquid_list.get(serial);
    }

    UpsertLiquid(liquid_state:STRUCT_LIQUID_STATE) {
        if(!this._liquid_list.has(liquid_state._name)) {
            const liquid:LIQUID = new LIQUID(
                liquid_state._name,
                liquid_state
            )

            this._liquid_list.set(liquid_state._name, liquid);
        }
        else {
            const liquid:LIQUID|undefined = this._liquid_list.get(liquid_state._name);
            if(liquid != undefined) {
                liquid.Set(liquid_state);
            }
        }
    }
}

export default LiquidManager;

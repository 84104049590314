import store from "@/store";
import WebsocketConnector from "./WebsocketConnector";
import { STRUCT_HYDROGEN_DATA, STRUCT_HYDROGEN_LOG_INFO2, STRUCT_HYDROGEN_SERIAL, STRUCT_PACKET } from "./WebsocketInterface";
import { E_MESSAGE_TYPE, E_APP_TYPE } from "./WebsocketProtocol";

export function Authentication(id: string, pw: string) {
    const packet: STRUCT_PACKET =
    {
        _header: {
            _msg_type: E_MESSAGE_TYPE.LOGIN,
            _index: E_APP_TYPE.HYDROGEN,
        },
        _contents: {
            _id: id,
            _pw: pw,
        },
    };

    store.commit('SetAuthId', id);
    store.commit('SetAuthPw', pw);

    WebsocketConnector.getInstance().send(packet);
}

export function RequestStackLogFileList() {
    if(store.getters.GetAuthState) {
        const packet:STRUCT_PACKET = {
            _header: {
                _msg_type: E_MESSAGE_TYPE.STACK_LOG_FILE_LIST,
            },
            _contents: {}
        };
    
        WebsocketConnector.getInstance().send(packet);
    }
}

export function RequestStackLogFileData(file_name_list:STRUCT_HYDROGEN_LOG_INFO2[]) {
    if(store.getters.GetAuthState) {
        const packet:STRUCT_PACKET = {
            _header: {
                _msg_type: E_MESSAGE_TYPE.STACK_LOG_FILE_DATA,
            },
            _contents: {
                _filename_list: file_name_list
            }
        };
    
        WebsocketConnector.getInstance().send(packet);
    }
}

export function RequestStackList() {
    if(store.getters.GetAuthState) {
        const packet: STRUCT_PACKET = {
            _header: {
                _msg_type: E_MESSAGE_TYPE.STACK_LIST,
            },
            _contents: {}
        };
    
        WebsocketConnector.getInstance().send(packet);
    }
}

export function RequestStackNoteList() {
    if(store.getters.GetAuthState) {
        const packet: STRUCT_PACKET = {
            _header: {
                _msg_type: E_MESSAGE_TYPE.STACK_NOTE_DATA,
            },
            _contents: {}
        };

        WebsocketConnector.getInstance().send(packet);
    }
}

export function UpdateStackNote(stack_note:STRUCT_HYDROGEN_DATA) {
    if(store.getters.GetAuthState) {
        const packet: STRUCT_PACKET = {
            _header: {
                _msg_type: E_MESSAGE_TYPE.STACK_NOTE_UPDATE,
            },
            _contents: {
                _stack_note_list: [stack_note],
            }
        };

        WebsocketConnector.getInstance().send(packet);
    }
}

export function StopReceiveStackData() {
    if(store.getters.GetAuthState) {
        const packet: STRUCT_PACKET = {
            _header: {
                _msg_type: E_MESSAGE_TYPE.STACK_SUBSCRIPTION,
            },
            _contents: {}
        };
    
        WebsocketConnector.getInstance().send(packet);
    }
}

export function RequestStackData(stack_serials:string[]) {
    if(store.getters.GetAuthState) {
        const serials:STRUCT_HYDROGEN_SERIAL[] = [];
        stack_serials.forEach((stack_serial:string) => {
            serials.push({
                _serial_num: stack_serial,
            })
        });
        const packet: STRUCT_PACKET = {
            _header: {
                _msg_type: E_MESSAGE_TYPE.STACK_SUBSCRIPTION,
            },
            _contents: {
                _subscription_list: serials
            }
        };
    
        WebsocketConnector.getInstance().send(packet);
    }
}
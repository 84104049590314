import store from "@/store";
import router from "@/router";
import { STRUCT_PACKET, STRUCT_HEADER, STRUCT_CONTENTS, STRUCT_HYDROGEN_INFO, STRUCT_HYDROGEN_LOG_INFO, STRUCT_HYDROGEN_DATA } from "./WebsocketInterface";
import { E_MESSAGE_TYPE } from "./WebsocketProtocol";
import StackManager from "../stack/StackManager";
import LogFileManager from "../report/LogFileManager";
import DeviceManager from "../device/DeviceManager";
import LiquidManager from "../liquid/LiquidManager";

export function Parse(packet_string:string) {
    try {
        const packet:STRUCT_PACKET = JSON.parse(packet_string);
        const header:STRUCT_HEADER = packet._header;
        const contents:STRUCT_CONTENTS = packet._contents;
        // console.log(packet)

        if(E_MESSAGE_TYPE.LOGIN == header._msg_type) {
            if(header._index != undefined && header._index != 0) {
                // Login Success !!
                store.commit('SetAuthState', true);
                
                if(router.currentRoute.path == `/auth`) {
                    router.push('/');
                }
            }
        }
        else if(E_MESSAGE_TYPE.STACK_LIST == header._msg_type) {
            const chunk_num:number|undefined = contents._chunk_num;
            const max_chunk_num:number|undefined = contents._max_chunk;
            const stack_list:STRUCT_HYDROGEN_INFO[]|undefined = contents._stack_list;
            
            if(chunk_num != undefined && max_chunk_num != undefined && stack_list != undefined) {
                StackManager.getInstance().UpdateStackList(chunk_num, max_chunk_num, stack_list);
                DeviceManager.getInstance().UpdateDeviceList(chunk_num, max_chunk_num, stack_list);
            }
        }
        else if(E_MESSAGE_TYPE.STACK_NOTE_DATA == header._msg_type) {
            const chunk_num:number|undefined = contents._chunk_num;
            const max_chunk_num:number|undefined = contents._max_chunk;
            const stack_note_list:STRUCT_HYDROGEN_DATA[]|undefined = contents._stack_note_list;
            
            if(chunk_num != undefined && max_chunk_num != undefined && stack_note_list != undefined) {
                StackManager.getInstance().UpdateStackNoteList(chunk_num, max_chunk_num, stack_note_list);
            }
        }
        else if(E_MESSAGE_TYPE.STACK_SUBSCRIPTION == header._msg_type) {
            // if(packet._contents._hydrogen_states?._serial_num.includes('S-000'))
            // {
            //     if(packet._contents._hydrogen_states) {
            //         const str = packet._contents._hydrogen_states._data;
            //         const base64decode = atob(str);
            //         let addr = '';
            //         let cmd_id = '';
            //         let can_data = '';
            //         for(let i = 0; i < base64decode.length; ++i) {
            //             const char = base64decode.charCodeAt(i).toString(2).padStart(8, '0');
            //             // console.log(i, base64decode.charCodeAt(i).toString(16).padStart(2, '0'))
            //             if(i < 2) {
            //                 addr += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
            //             }
            //             else if(i < 4) {
            //                 cmd_id += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
            //             }
            //             else {
            //                 can_data += base64decode.charCodeAt(i).toString(16).padStart(2, '0');
            //             }
            //         }
            //         console.log(contents._hydrogen_states?._serial_num, addr, cmd_id, can_data)
            //     }
            // }
            if(contents._hydrogen_states) {
                const serial_num:string = contents._hydrogen_states._serial_num;
                const data:string = contents._hydrogen_states._data;
                // console.log(serial_num, data)

                StackManager.getInstance().UpdateStackData(serial_num, data);
            }
        }
        else if(E_MESSAGE_TYPE.STACK_LOG_FILE_LIST == header._msg_type) {
            // console.log(packet)
            const chunk_num:number|undefined = contents._chunk_num;
            const max_chunk_num:number|undefined = contents._max_chunk;
            const file_list:STRUCT_HYDROGEN_LOG_INFO[]|undefined = contents._file_list;

            if(chunk_num != undefined && max_chunk_num != undefined && file_list != undefined) {
                LogFileManager.getInstance().UpdateLogFileList(chunk_num, max_chunk_num, file_list);
            }
        }
        else if(E_MESSAGE_TYPE.STACK_LOG_FILE_DATA == header._msg_type) {
            // console.log(packet)
            const chunk_num:number|undefined = contents._chunk_num;
            const max_chunk_num:number|undefined = contents._max_chunk;
            const file_data:string|undefined = contents._file_data;
            const file_name:string|undefined = contents._filename;
            const error:number|undefined = contents._error;

            if(chunk_num != undefined && max_chunk_num != undefined && file_data != undefined && file_name != undefined) {
                LogFileManager.getInstance().UpdateLogFileData(chunk_num, max_chunk_num, file_data, file_name);
            }
        }
        else if(E_MESSAGE_TYPE.LIQUID_GENERATOR_STATE == header._msg_type) {
            if(packet._contents._liquid_state) {
                LiquidManager.getInstance().UpsertLiquid(packet._contents._liquid_state);
            }
        }
    } catch (error) {
        console.error(error);
    }
}
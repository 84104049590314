import store from "@/store";
import { STRUCT_PACKET } from "./WebsocketInterface";
import { Parse } from "./WebsocketParser";
import { Authentication, RequestStackList, RequestStackNoteList } from "./WebsocketSender";

class WebsocketConnector {
    private static instance: WebsocketConnector;

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    private ws: WebSocket | null = null;
    private server_url = 'wss://hsm.hogreenair.com/ws';

    constructor() {
        console.log(document.location.protocol)
        this.connect();
        setInterval(() => {
            RequestStackList();
            RequestStackNoteList();
        }, 1000);
    }

    private connect() {

        this.ws = new WebSocket(this.server_url);

        this.ws.onopen = (e: any) => {
            console.log(e);
            store.commit('SetServerConnState', true);
        };

        this.ws.onmessage = (e: any) => {
            Parse(e.data);
        };

        this.ws.onerror = (e: any) => {
            console.error(e);
        };

        this.ws.onclose = (e: any) => {
            store.commit('SetServerConnState', false);
            // console.log(e);
            const is_auth = store.getters.GetAuthState;
            // store.commit("SetAuthState", false)
            // this.ws = null;
            if(this.ws) {
                this.ws.close();
            }

            if (is_auth) {
                this.connectWithAuth();
            }
            else {
                this.connect();
            }
        };
    }

    private connectWithAuth() {
        this.ws = new WebSocket(this.server_url);

        this.ws.onopen = (e: any) => {
            console.log(e);
            store.commit('SetServerConnState', true);
            Authentication(store.getters.GetAuthId, store.getters.GetAuthPw);
        };

        this.ws.onmessage = (e: any) => {
            Parse(e.data);
        };

        this.ws.onerror = (e: any) => {
            console.error(e);
        };

        this.ws.onclose = (e: any) => {
            store.commit('SetServerConnState', false);
            // console.log(e);
            const is_auth = store.getters.GetAuthState;
            // store.commit("SetAuthState", false)
            if(this.ws) {
                this.ws.close();
            }

            if (is_auth) {
                this.connectWithAuth();
            }
            else {
                this.connect();
            }
        };
    }

    public send(msg: STRUCT_PACKET) {
        try {
            this.ws?.send(JSON.stringify(msg));
        } catch (error) {
            console.error(error);
        }
    }
}

export default WebsocketConnector;

<template>
  <report-component />
</template>

<script lang="ts">
  import Vue from 'vue'
  import ReportComponent from '../components/Report.vue'

  export default Vue.extend({
    name: 'ReportView',

    components: {
      ReportComponent,
    },
  })
</script>

<template>
  <div class="bg" style="width: 100vw; height: 100vh; ">
    <v-container class="pa-0 ma-0" style="max-width: 100vw; height: 100vh; max-height: 100vh;">
      <v-dialog
        v-model="note_dialog"
        width="50%"
      >
        <v-card>
          <v-card-title><strong>{{ note_serial }}</strong>'s NOTE</v-card-title>
          <v-card-text>
            <v-textarea outlined v-model="note_text">{{ note_text }}</v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="() => {UpdateStackNote(); note_dialog = false;}">save</v-btn>
            <v-btn color="grey" @click="note_dialog = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row class="pa-0 ma-0" align="center" justify="center" style="height: 6vh;">
        <v-col class="pa-0 ma-0">
          <v-img src="@/assets/hogreen-logo.png" :width="130"></v-img>
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: center;">
          <!-- <h1><strong>Stack Monitoring</strong></h1> -->
        </v-col>

        <v-col class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: right;">
          <v-btn small @click="OnClickGoReport"><v-icon small>mdi-chart-line</v-icon>report</v-btn>
          &nbsp;
          <v-chip small label>
            <v-icon small>mdi-account</v-icon>{{ store_auth_id }}<v-btn plain small @click="OnClickLogout"><v-icon small>mdi-logout</v-icon>logout</v-btn>
          </v-chip>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0" style="height: 6vh;">

        <v-spacer></v-spacer>

        <v-col cols="6" class="pa-0 ma-0" style="display: flex; align-items: center; justify-content: right;">
          <v-btn outlined large width="20%" color="primary secondary--text" class="pa-0 ma-1">
            <v-container class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" align="start" justify="start"><h3 style="letter-spacing: 0ch;">connect</h3></v-row>
              <v-row class="pa-0 ma-0" align="end" justify="end"><h2>{{ stack_manager.GetStackStateCount().run }}</h2></v-row>
            </v-container>
          </v-btn>
          <v-btn outlined large width="20%" color="warning secondary--text" class="pa-0 ma-1">
            <v-container class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" align="start" justify="start"><h3 style="letter-spacing: 0ch;">warning</h3></v-row>
              <v-row class="pa-0 ma-0" align="end" justify="end"><h2>{{ stack_manager.GetStackStateCount().warning }}</h2></v-row>
            </v-container>
          </v-btn>
          <v-btn outlined large width="20%" color="error secondary--text" class="pa-0 ma-1">
            <v-container class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" align="start" justify="start"><h3 style="letter-spacing: 0ch;">error</h3></v-row>
              <v-row class="pa-0 ma-0" align="end" justify="end"><h2>{{ stack_manager.GetStackStateCount().error }}</h2></v-row>
            </v-container>
          </v-btn>
          <v-btn outlined large width="20%" color="grey secondary--text" class="pa-0 ma-1">
            <v-container class="pa-0 ma-0">
              <v-row class="pa-0 ma-0" align="start" justify="start"><h3 style="letter-spacing: 0ch;">disconnect</h3></v-row>
              <v-row class="pa-0 ma-0" align="end" justify="end"><h2>{{ stack_manager.GetStackStateCount().offline }}</h2></v-row>
            </v-container>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0" style="height: 6vh;">
        <v-col class="pa-2 ma-0" cols="12">
          <v-card class="pa-0 ma-0 bg-gradient-blue grey--text">
            <v-card-title class="pa-1 ma-0">Device ({{ devices.length }})</v-card-title>
          </v-card>
        </v-col>

      </v-row>
      
      <v-row class="pa-0 ma-0" style="height: 21vh; overflow-x: auto; overflow-y: hidden;">

        <v-carousel :value="device_page" height="21vh" hide-delimiter-background show-arrows-on-hover>
          <template v-for="device_seperate in Math.ceil(devices.length / 6)">
            <v-carousel-item :value="device_seperate - 1" :key="'device'+device_seperate">
              <v-container>
                <v-row>
                  <v-col cols="2" v-for="(device, index) in devices.slice((device_seperate-1)*6, (device_seperate-1)*6 + 6)" :key="device.Get().device_serial + index">
                    <!-- {{ device_seperate-1 }} {{ index }} {{ device.Get().device_serial }} -->
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card outlined class="pa-2 ma-0 bg-gradient-blue" style="height: 100%;" elevation="10" :color="hover ? 'secondary' : ''" v-on:click="OnClickDevice(device.Get().device_serial)">
                          <v-card-title class="pa-0 ma-0">
                            <v-btn disabled color="grey" x-small icon><v-icon>mdi-chip</v-icon></v-btn>&nbsp;{{ device.Get().device_index_alias }}
                          </v-card-title>

                          <v-card-text class="pa-0 ma-0" v-for="(stack_serial, idx) in device.Get().stack_list.sort()" :key="stack_serial + idx">
                            <v-container class="pa-0 ma-0">
                              
                              <v-row v-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 0" class="pa-0 ma-0 primary--text" align="center" justify="space-between">
                                <v-icon small color="primary">mdi-link-variant</v-icon>{{ stack_serial }}
                              </v-row>

                              <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 1" class="pa-0 ma-0 warning--text" align="center" justify="space-between">
                                <v-icon small color="warning">mdi-link-variant</v-icon>{{ stack_serial }}
                              </v-row>
                              <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 2" class="pa-0 ma-0 warning--text" align="center" justify="space-between">
                                <v-icon small color="warning">mdi-link-variant</v-icon>{{ stack_serial }}
                              </v-row>

                              <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 3" class="pa-0 ma-0 error--text" align="center" justify="space-between">
                                <v-icon small color="error">mdi-link-variant</v-icon>{{ stack_serial }}
                              </v-row>
                              <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 4" class="pa-0 ma-0 error--text" align="center" justify="space-between">
                                <v-icon small color="error">mdi-link-variant</v-icon>{{ stack_serial }}
                              </v-row>

                              <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 0" class="pa-0 ma-0 grey--text" align="center" justify="space-between">
                                <v-icon small color="grey">mdi-link-variant</v-icon>{{ stack_serial }}
                              </v-row>

                            </v-container>
                          </v-card-text>
                        
                        </v-card>
                      </template>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
          </template>
        </v-carousel>
        <!-- <v-col class="pa-2 ma-0" cols="auto" v-for="(device, index) in devices" :key="device.Get().device_serial + index">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card outlined class="pa-2 ma-0 bg-gradient-blue" style="min-width: 200px; height: 100%;" elevation="10" :color="hover ? 'secondary' : ''" v-on:click="OnClickDevice(device.Get().device_serial)">
                <v-card-title class="pa-0 ma-0">
                  <v-btn disabled color="grey" x-small icon><v-icon>mdi-chip</v-icon></v-btn>&nbsp;{{ device.Get().device_serial }}
                </v-card-title>

                <v-card-text class="pa-0 ma-0" v-for="(stack_serial, idx) in device.Get().stack_list.sort()" :key="stack_serial + idx">
                  <v-container class="pa-0 ma-0">
                    
                    <v-row v-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 0" class="pa-0 ma-0 primary--text" align="center" justify="space-between">
                      <v-icon small color="primary">mdi-link-variant</v-icon>{{ stack_serial }}
                    </v-row>

                    <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 1" class="pa-0 ma-0 warning--text" align="center" justify="space-between">
                      <v-icon small color="warning">mdi-link-variant</v-icon>{{ stack_serial }}
                    </v-row>
                    <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 2" class="pa-0 ma-0 warning--text" align="center" justify="space-between">
                      <v-icon small color="warning">mdi-link-variant</v-icon>{{ stack_serial }}
                    </v-row>

                    <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 3" class="pa-0 ma-0 error--text" align="center" justify="space-between">
                      <v-icon small color="error">mdi-link-variant</v-icon>{{ stack_serial }}
                    </v-row>
                    <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 1 && stack_manager.GetStackFromStackSerial(stack_serial)?.Get().error_state == 4" class="pa-0 ma-0 error--text" align="center" justify="space-between">
                      <v-icon small color="error">mdi-link-variant</v-icon>{{ stack_serial }}
                    </v-row>

                    <v-row v-else-if="stack_manager.GetStackFromStackSerial(stack_serial)?.Get().connect_state == 0" class="pa-0 ma-0 grey--text" align="center" justify="space-between">
                      <v-icon small color="grey">mdi-link-variant</v-icon>{{ stack_serial }}
                    </v-row>

                  </v-container>
                </v-card-text>
              
              </v-card>
            </template>
          </v-hover>
        </v-col> -->

      </v-row>
      
      <v-row class="pa-0 ma-0" style="height: 6vh;">
        <v-col class="pa-2 ma-0" :cols="liquids.length > 0 ? 8 : 12">
          <v-card class="pa-0 ma-0 bg-gradient-blue grey--text">
            <v-card-title class="pa-1 ma-0">
              <v-container class="pa-0 ma-0" style="max-width: 100%;">
                <v-row class="pa-0 ma-0">
                  <v-col cols="3" class="pa-0 ma-0">
                    Stack ({{ stacks.length }})
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="3" class="pa-0 ma-0">
                    <v-text-field dense v-model="search_text" hide-details="" outlined placeholder="search" clearable></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col class="pa-2 ma-0" cols="4" v-if="liquids.length > 0">
          <v-card class="pa-0 ma-0 bg-gradient-blue grey--text">
            <v-card-title class="pa-1 ma-0">
              <v-container class="pa-0 ma-0" style="max-width: 100%;">
                <v-row class="pa-0 ma-0">
                  <v-col cols="9" class="pa-0 ma-0">
                    Liquid Generator ({{ liquids.length }})
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>

     

      <v-row class="pa-0 ma-0" style="height: 55vh;">
        <v-col class="pa-2 ma-0" :cols="liquids.length > 0 ? 8 : 12" style="height: 100%; overflow-y: auto;">
          <v-data-table
          :items="stacks"
          class="pa-2 ma-0 transparent"
          :headers="stack_tb_headers"
          :items-per-page="-1"
          hide-default-footer
          dense
          :search="search_text"
          >

          <template v-slot:[`item.connect_state`]="{ item }">
            <v-icon v-if="item.connect_state == 1" color="success">mdi-check</v-icon>
            <v-icon v-if="item.connect_state == 0" color="error">mdi-close</v-icon>
          </template>

          <template v-slot:[`item.summary_run_state`]="{ item }">
            <v-icon v-if="item.summary_run_state == 1" color="success">mdi-check</v-icon>
            <v-icon v-if="item.summary_run_state == 0" color="error">mdi-close</v-icon>
          </template>

          <template v-slot:[`item.failure_state`]="{ item }">
            <v-icon v-if="item.failure_state == -1" color="secondary">mdi-head-snowflake</v-icon>
            <v-icon v-if="item.failure_state == 0" color="error">mdi-head-snowflake</v-icon>
            <v-icon v-if="item.failure_state == 1" color="success">mdi-head-snowflake</v-icon>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="() => {
              note_dialog = true; 
              note_text = item.note;
              note_serial = item.stack_serial;
            }">
              <v-icon v-if="item.note.length == 0" color="grey">mdi-note</v-icon>
              <v-icon v-else color="primary">mdi-note</v-icon>
            </v-btn>

            <v-btn icon @click="OnClickStackItem(item)"><v-icon>mdi-magnify</v-icon></v-btn>
          </template>

          <template v-slot:[`item.error_state`]="{ item }">
            <v-icon v-if="item.error_state == 1" color="error">mdi-alert</v-icon>
          </template>

          <template v-slot:[`item.model_type`]="{ item }">
            {{ 
              item.model_type == 0
              ? 'IE-POWER 4' : 'IE-SOAR 2.4'
            }}
          </template>

          <template v-slot:[`item.use_type`]="{ item }">
            {{ 
              item.use_type == 1
              ? 'UAV' : 'ESS'
            }}
          </template>

          <template v-slot:[`item.last_uptime`]="{ item }">
            {{ LastUptime(item.last_uptime) }}
          </template>

          <template v-slot:[`item.connection_type`]="{ item }">
            {{ item.connection_type == 0 ? 'UART' : item.connection_type == 1 ? 'NETWORK' : 'UNKNOWN' }}
          </template>

          </v-data-table>
        </v-col>

        <v-col v-if="liquids.length > 0" class="pa-2 ma-0" cols="4" style="height: 100%; overflow-y: auto;">
          <v-carousel v-model="liquid_generator_current_index" hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="liquid in liquids" :key="liquid.Get().liquid_serial">
              <v-card color="transparent">

                <v-system-bar window>
                  <v-icon>mdi-transfer</v-icon>
                  {{ liquid.Get().liquid_serial }}
                  <v-btn x-small class="ma-2" @click="OnClickLiquidLive(liquid)">live</v-btn>
                  <v-btn x-small class="ma-0" @click="OnClickLiquidReport(liquid)">report</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon>mdi-refresh</v-icon>
                  updated:
                  {{ liquid.Get().liquid_state._year }}/
                  {{ liquid.Get().liquid_state._month.toString().padStart(2, '0') }}/
                  {{ liquid.Get().liquid_state._day.toString().padStart(2, '0') }}
                  <!-- &nbsp; -->
                  {{ liquid.Get().liquid_state._hour.toString().padStart(2, '0') }}:
                  {{ liquid.Get().liquid_state._min.toString().padStart(2, '0') }}:
                  {{ liquid.Get().liquid_state._sec.toString().padStart(2, '0') }}
                </v-system-bar>
                <!-- <v-avatar rounded label large style="min-width: 100px; align-items: center; justify-content: center;"><v-icon>mdi-transfer</v-icon>{{ liquid.Get().liquid_serial }}</v-avatar> -->
                <v-card-text>
                  <v-row class="pa-0">Real-time liquefaction rate(L/hr)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._lhr }}</v-row>
                  <v-row class="pa-0">Liquefaction rate per kW(l/hr/kW)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._lhrkw }}</v-row>
                  <v-row class="pa-0">Liquefaction Kg per Day(kg/day)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._kg_per_day }}</v-row>
                  <v-row class="pa-0">GH2Inlet(barA)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._gh2_inlet }}</v-row>
                  <v-row class="pa-0" :class="[(liquid.Get().liquid_state._gh2_vent < 0 || 1 < liquid.Get().liquid_state._gh2_vent) ? 'warning--text' : '' ]">GH2 Vent(bar)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._gh2_vent }}</v-row>
                  <v-row class="pa-0">Vacuum(mBar)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._vacuum }}</v-row>
                  <v-row class="pa-0">Outer Tank(mBar)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._outer_tank_vacuum }}</v-row>
                  <v-row class="pa-0" :class="[(liquid.Get().liquid_state._temperature_1 == 0 || 20 < liquid.Get().liquid_state._temperature_1) ? 'warning--text' : '' ]">TS1(k)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._temperature_1 }}</v-row>
                  <v-row class="pa-0">MFC Mass Flow(SLPM)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._mfc_gs }}</v-row>
                  <v-row class="pa-0">PositionerValve(%)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._positioner_valve }}</v-row>
                  <v-row class="pa-0" :class="[(liquid.Get().liquid_state._coolant_in_temp == 0 || 25 <= liquid.Get().liquid_state._coolant_in_temp) ? 'warning--text' : '' ]">CoolantInTemp(℃)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._coolant_in_temp }}</v-row>
                  <v-row class="pa-0">CoolantOutTemp(℃)<v-spacer></v-spacer>{{ liquid.Get().liquid_state._coolant_out_temp }}</v-row>
                  <v-row class="pa-0">Cooler Status<v-spacer></v-spacer>{{ liquid.Get().liquid_state._cooler_status ? 'Running' : 'Stop' }}</v-row>
                  
                </v-card-text>
                
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        
      </v-row>
      
    </v-container>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import DEVICE from '@/services/device/Device';
import LIQUID from '@/services/liquid/Liquid';
import STACK from '@/services/stack/Stack';
import StackManager from '@/services/stack/StackManager';
import { STRUCT_HYDROGEN_DATA } from '@/services/websocket/WebsocketInterface';
import { RequestStackData, StopReceiveStackData, UpdateStackNote } from '@/services/websocket/WebsocketSender';
import store from '@/store';
import axios from 'axios';
import Vue, { computed } from 'vue'

export default Vue.extend({
  name: 'DashBoard',

  data() {
    return {
      liquid_generator_current_index: 0,
      liquid_generator_last_warning_time: 0,
      device_page: 0,
      note_dialog: false,
      note_text: '',
      note_serial: '',
      search_text: '' as string,
      stack_manager: StackManager.getInstance() as StackManager,
      stacks: [] as STACK[],
      devices: [] as DEVICE[],
      liquids: [] as LIQUID[],
      stack_tb_headers: [
        {
          text: 'Serial',
          value: 'stack_serial'
        },
        {
          text: 'Alias',
          value: 'alias',
        },
        {
          text: 'Model',
          value: 'model_type'
        },
        // {
        //   text: 'Using Type',
        //   value: 'use_type',
        // },
        {
          text: 'Connection',
          value: 'connection_type',
        },
        {
          text: 'Connect',
          value: 'connect_state',
        },
        {
          text: 'Running',
          value: 'summary_run_state',
        },
        {
          text: 'Error',
          value: 'error_state',
        },
        {
          text: 'AI',
          value: 'failure_state',
        },
        {
          text: 'Last Uptime',
          value: 'last_uptime',
        },
        {
          text: 'Linked',
          value: 'edge_alias',
        },
        {
          text: 'Action',
          value: 'action'
        },
        // {
        //   text: 'Note',
        //   value: 'note',
        // },
      ],
    };
  },

  mounted() {
      // setInterval(() => {
      //   console.log(this.liquid_generator_current_index)
      // }, 500)
  },

  setup() {
    const store_auth_id = computed<string>(() => store.getters.GetAuthId);
    const store_stacks = computed<STACK[]>(() => store.getters.GetStacks);
    const store_devices = computed<DEVICE[]>(() => store.getters.GetDevices);
    const store_liquids = computed<LIQUID[]>(() => store.getters.GetLiquids);
    return { store_auth_id, store_stacks, store_devices, store_liquids };
  },

  watch: {
    store_stacks: {
      handler(val, oldVal) {
        this.stacks = val;
        // this.stacks = [...val,...val];
      },
    },
    store_devices: {
      handler(val, oldVal) {
        this.devices = val;
        // this.devices = [...val,...val];
      },
    },
    store_liquids: {
      handler(val, oldVal) {
        this.liquids = val;

        let warn = false;
        this.liquids.forEach((liquid:LIQUID, index) => {
          if(warn == false || this.liquid_generator_last_warning_time == 0 ) {
            if(Date.now() - this.liquid_generator_last_warning_time > 10000) {
              if(
              (liquid.Get().liquid_state._gh2_vent < 0 || 1 < liquid.Get().liquid_state._gh2_vent)
              || (liquid.Get().liquid_state._temperature_1 == 0 || 20 < liquid.Get().liquid_state._temperature_1)
              || (liquid.Get().liquid_state._coolant_in_temp < 0 || 25 < liquid.Get().liquid_state._coolant_in_temp)
            ) {
              warn = true;
              this.liquid_generator_last_warning_time = Date.now();
              this.liquid_generator_current_index = index;
            }
            }
          }
        });
        // this.liquids = [...val,...val];
      },
    },
  },

  beforeUpdate() {
      // console.log('update')
  },

  methods: {
    OnClickLogout() {
      window.location.reload();
    },
    OnClickGoReport() {
      StopReceiveStackData();
      router.push({name: 'report'});
    },
    OnClickStack(serial:string) {
      if(this.$route.path !== `/stack/${serial}`) {
        router.push({name: 'stack', params: {stack_serial: serial}});
        RequestStackData([serial]);
      }
    },
    OnClickDevice(device_serial:string) {
      if(this.$route.path !== `/device/${device_serial}`) {
        router.push({name: 'device', params: {device_serial: device_serial}});
      }
    },
    OnClickStackItem(stack:STACK) {
      this.OnClickStack(stack.Get().stack_serial);
    },
    OnClickLiquidLive(liquid:LIQUID) {
      if(this.$route.path !== `/lqd/${liquid.Get().liquid_serial}`) {
        router.push({name: 'lqd', params: {liquid_serial: liquid.Get().liquid_serial}});
      }
    },
    OnClickLiquidReport(liquid:LIQUID) {
      if(this.$route.path !== `/lqr/${liquid.Get().liquid_serial}`) {
        router.push({name: 'lqr', params: {liquid_serial: liquid.Get().liquid_serial}});
      }
    },
    LastUptime(timestamp:number) {
      const dt_now = Date.now();
      const last_uptime = timestamp * 1e3;

      const diff_ms = dt_now - last_uptime;
      const diff_sec = Math.floor(diff_ms / 1e3);

      const year = 31536000;
      const month = 2628000;
      const day = 86400;
      const hour = 3600;
      const minute = 60;

      if(Math.floor((diff_sec / year)) > 0) {
        return `${Math.floor((diff_sec / year))} year ago`;
      }
      else if(Math.floor((diff_sec / month)) > 0) {
        return `${Math.floor((diff_sec / month))} month ago`;
      }
      else if(Math.floor((diff_sec / day)) > 0) {
        return `${Math.floor((diff_sec / day))} day ago`;
      }
      else if(Math.floor((diff_sec / hour)) > 0) {
        return `${Math.floor((diff_sec / hour))} hour ago`;
      }
      else if(Math.floor((diff_sec / minute)) > 0) {
        return `${Math.floor((diff_sec / minute))} minute ago`;
      }

      return `${diff_sec} sec ago`
    },
    UpdateStackNote() {
      const stack_note:STRUCT_HYDROGEN_DATA = {
        _serial_num: this.note_serial,
        _data: this.note_text,
      }
      UpdateStackNote(stack_note);
    }
  }
})
</script>

<style scoped>
.bg {
  background-image: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),url(@/assets/h2img.png);
  background-position: right bottom;
}

.bg-gradient-blue {
  background: linear-gradient(90deg, rgba(0,40,40,1) 0%, rgba(0,0,20,1) 35%, black 100%);
}
.bg-gradient-deep-blue {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(22,22,22,1) 35%, black 100%);
}
</style>

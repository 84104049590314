<template>
  <liquid-report-component :liquid_serial="liquid_serial"/>
</template>

<script lang="ts">
  import Vue from 'vue'
  import LiquidReportComponent from '../components/LiquidReport.vue'

  export default Vue.extend({
    name: 'ReportView',

    props: {
      liquid_serial: {
        type: String,
      }
    },

    components: {
      LiquidReportComponent,
    },
  })
</script>

<template>
    <v-snackbar v-model="snackbar" top :timeout="timeout" :color="color" :multi-line="multiline">
        <div style="display: flex; align-items: center; justify-content: center;">
            {{ text }}
        </div>
    </v-snackbar>
</template>

<script lang="ts">
import store from '@/store';
import Vue, { computed } from 'vue'

export default Vue.extend({
    name: 'SnackBar',

    data: () => ({
        snackbar: false,
        text: '',
        timeout: 500,
        color: 'default',
        multiline: false,
    }),

    setup() {
        const store_auth_id = computed<string>(() => store.getters.GetAuthId);
        const store_server_conn_state = computed<boolean>(() => store.getters.GetServerConnState);
        return {store_auth_id, store_server_conn_state};
    },

    watch: {
        store_auth_id: {
            handler(val, oldVal) {
                this.text = `hello, ${val} :)`;
                this.color = 'primary';
                this.snackbar = true;
            },
        },
        store_server_conn_state: {
            handler(val, oldVal) {
                if(val) {
                    this.text = 'server connected';
                    this.color = 'success';
                }
                else {
                    this.text = 'server disconnected';
                    this.color = 'error';
                }
                this.snackbar = true;
            },
        },
    },
})
</script>

<style scoped></style>
export enum E_MESSAGE_TYPE {
  NONE = 0,
  LOGIN,
  RESERVE_1,
  RESERVE_2,
  RESERVE_3,
  LOST_AUTHENTICATION,
  STATE_NOTI,
  MISSION_START,
  MISSION_SUSPEND,
  MISSION_RESUME,
  MISSION_STOP,
  MISSION_REGISTER,
  MISSION_ADD,
  MISSION_REGISTER_AUTOSTART,
  MISSION_AVOIDANCE_REQUEST,
  MISSION_AVOIDANCE_SWITCH,
  RTT,
  LOG,
  MISSION_GIMBAL_CONTROL,
  MISSION_RC_CHANNEL,
  STATUS,
  HYDROGEN_STATUS,
  HYDROGEN_FAULT,
  MISSION_MANUAL_CONTROL,
  FENCE_AI_COORDINATE = 80,
  FENCE_AI_IMAGE,
  DRONE_TV_CHANNEL = 100,
  FC_LOG = 200,
  FC_LOG_LIST_REPLY,
  FC_LOG_DATA_REPLY,
  HYDROGEN_SYSTEM_MSG_START = 1000,
  STACK_LIST,
  STACK_SUBSCRIPTION,
  STACK_LOG_FILE_LIST,
  STACK_LOG_FILE_DATA,
  STACK_LOG_FILE_LIST_DRONE,
  STACK_LOG_FILE_LIST_ESS,
  STACK_CMD,
  STACK_NOTE_DATA,
  STACK_NOTE_UPDATE,
  HYDROGEN_SYSTEM_MSG_END = 1500,
  UPDATER_MSG_START = 2000,
  UPDATE_REQ,
  UPDATE_RES,
  UPDATER_MSG_END = 2100,
  AI_SYSTEM_MSG_START = 3000,
  AI_REQ_STREAM_LIST,
  AI_RES_STREAM_LIST,
  AI_IMAGE,
  AI_META,
  AI_SYSTEM_MSG_END = 3100,
  LIQUID_GENERATOR_MSG_START = 4000,
  LIQUID_GENERATOR_STATE,
  LIQUID_GENERATOR_MSG_END = 4100,
  END   
}

export enum E_APP_TYPE {
  NONE = 0,
  FC = 1 << 0,
  ESS = 1 << 1,
  GCS = 1 << 2,
  GCS_MONITOR = 1 << 3,
  DRONE_TV = 1 << 4,
  HYDROGEN = 1 << 5,
  ADMIN = 1 << 6,
  AI_CONTAINER = 1 << 7,
  AI_MODULE = 1 << 8,
  LIQUID_GENERATOR = 1 << 9,
  STREAMING_STORAGE = 1 << 20,
}
import Vue from 'vue'
import Vuex from 'vuex'
import STACK from '@/services/stack/Stack'
import { LINE_CHART_SERIES_FROM, RANGE_CHART_SERIES_FORM, ST_LOG_FILE_INFO } from '@/services/report/LogFileManager'
import DEVICE from '@/services/device/Device'
import { NAV_STACK_INFO } from '@/services/stack/StackManager'
import LIQUID from '@/services/liquid/Liquid'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    server_conn_state: false,
    auth_state: false,
    auth_id: '',
    auth_pw: '',
    stacks: [] as STACK[],
    devices: [] as DEVICE[],
    liquids: [] as LIQUID[],
    log_files: [] as ST_LOG_FILE_INFO[],

    nav_stack_infos: [] as NAV_STACK_INFO[],

    report_state_data: [] as RANGE_CHART_SERIES_FORM[],
    report_fan_pulse_data: [] as RANGE_CHART_SERIES_FORM[],
    report_louver_open_data: [] as RANGE_CHART_SERIES_FORM[],
    report_output_data: [] as LINE_CHART_SERIES_FROM[],
    report_anode_pressure_data: [] as LINE_CHART_SERIES_FROM[],
    report_fan_sp_duty_data: [] as LINE_CHART_SERIES_FROM[],
    report_louver_position_data: [] as LINE_CHART_SERIES_FROM[],
    report_temperature_data: [] as LINE_CHART_SERIES_FROM[],

    report_soar_state_data: [] as RANGE_CHART_SERIES_FORM[],
    report_soar_data: [] as LINE_CHART_SERIES_FROM[],
  },
  getters: {
    GetServerConnState: (state) => {
      return state.server_conn_state;
    },
    GetAuthState: (state) => {
      return state.auth_state;
    },
    GetAuthId: (state) => {
      return state.auth_id;
    },
    GetAuthPw: (state) => {
      return state.auth_pw;
    },
    GetStacks: (state) => {
      return state.stacks;
    },
    GetDevices: (state) => {
      return state.devices;
    },
    GetLiquids: (state) => {
      return state.liquids;
    },
    GetLogFiles: (state) => {
      return state.log_files;
    },
    GetStackNavList: (state) => {
      return state.nav_stack_infos;
    },
    GetReportState: (state) => {
      return state.report_state_data;
    },
    GetReportFanPulse: (state) => {
      return state.report_fan_pulse_data;
    },
    GetReportLouverOpen: (state) => {
      return state.report_louver_open_data;
    },
    GetReportOutput: (state) => {
      return state.report_output_data;
    },
    GetReportAnodePressure: (state) => {
      return state.report_anode_pressure_data;
    },
    GetReportFanSpDuty: (state) => {
      return state.report_fan_sp_duty_data;
    },
    GetReportLouverPosition: (state) => {
      return state.report_louver_position_data;
    },
    GetReportTemperature: (state) => {
      return state.report_temperature_data;
    },
    GetReportSoarState: (state) => {
      return state.report_soar_state_data;
    },
    GetReportSoarData: (state) => {
      return state.report_soar_data;
    },
  },
  mutations: {
    SetServerConnState(state, value) {
      state.server_conn_state = value;
    },
    SetAuthState(state, value) {
      state.auth_state = value;
    },
    SetAuthId(state, value) {
      state.auth_id = value;
    },
    SetAuthPw(state, value) {
      state.auth_pw = value;
    },
    SetStacks(state, value) {
      state.stacks = value;
    },
    SetDevices(state, value) {
      state.devices = value;
    },
    SetLiquids(state, value) {
      state.liquids = value;
    },
    SetLogFiles(state, value) {
      state.log_files = value;
    },
    SetStackNavList(state, value) {
      state.nav_stack_infos = value;
    },
    SetReportState(state, value) {
      state.report_state_data = value;
    },
    SetReportFanPulse(state, value) {
      state.report_fan_pulse_data = value;
    },
    SetReportLouverOpen(state, value) {
      state.report_louver_open_data = value;
    },
    SetReportOutput(state, value) {
      state.report_output_data = value;
    },
    SetReportAnodePressure(state, value) {
      state.report_anode_pressure_data = value;
    },
    SetReportFanSpDuty(state, value) {
      state.report_fan_sp_duty_data = value;
    },
    SetReportLouverPosition(state, value) {
      state.report_louver_position_data = value;
    },
    SetReportTemperature(state, value) {
      state.report_temperature_data = value;
    },
    SetReportSoarState(state, value) {
      state.report_soar_state_data = value;
    },
    SetReportSoarData(state, value) {
      state.report_soar_data = value;
    },
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <device-detail :device_serial="device_serial" />
</template>

<script lang="ts">
  import DEVICE from '@/services/device/Device'
  import Vue from 'vue'
  import DeviceDetail from '../components/DeviceDetail.vue'

  export default Vue.extend({
    name: 'StackDetailView',

    props: {
      device_serial: {
        type: DEVICE,
        required: true,
      }
    },

    components: {
      DeviceDetail,
    },
  })
</script>

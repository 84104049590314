<template>
  <stack-detail :stack_serial="stack_serial" />
</template>

<script lang="ts">
  import STACK from '@/services/stack/Stack'
  import Vue from 'vue'
  import StackDetail from '../components/StackDetail.vue'

  export default Vue.extend({
    name: 'StackDetailView',

    props: {
      stack_serial: {
        type: STACK,
        required: true,
      }
    },

    components: {
      StackDetail,
    },
  })
</script>
